@import "src/index.scss";

.quest-risk-table {
    margin-bottom: 25px;

    .table-row {
        border-top: 1px solid #e0e0e0;
        border-left: 1px solid #e0e0e0;
        border-right: 1px solid #e0e0e0;
    }

    .no-approvers {
        color: #9b9b9b;
        display: flex;
        justify-content: center;
        margin: $default-margin();
        font-weight: bold;
    }

    .main-row {
        border: 1px solid rgba(224, 224, 224, 1);
    }

    .spacing {
        height: 12px;
    }

    .risk-classification {
        font-weight: 600;
        color: #9b9b9b;
    }

    .likelyhood {
        font-weight: 600;
    }

    .severity {
        font-weight: 600;
    }

    .risk-high {
        font-weight: 600;
        color: #EB5E5E;
    }

    .risk-low {
        font-weight: 600;
        color: #3CBE46;
    }

    .risk-medium {
        font-weight: 600;
        color: #EA910D;
    }

    .risk-insignificant {
        font-weight: 600;
        color: #1946A2;
    }
}