@import "src/index.scss";
.timesheet-page-container {
  .filter-dropdown {
    width: 10rem;
    margin-right: 8px;
  }

  /* Media query to adjust the element on mobile devices */
  @media (max-width: 600px) {
    .filter-dropdown {
      max-width: 7rem; /* adjust the element on screens with a width of 600px or less (mobile) */
    }
  }
}
