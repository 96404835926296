@import "src/index.scss";

.ejourney-header {
  background-color: white;

  .row-1 {
    .dot {
      background-color: $ejourney;
    }

    .label-1 {
      font-size: 14px;
      color: $ejourney;
    }
  }

  .row-2 {
    .label-2 {
      font-size: 18px;
      color: #4a4a4a;
    }
  }

  .row-3 {
    .col-1 {
      .chip-1 {
        .label {
          color: white;
        }
      }

      .key-value-1 {
        .key {
          font-size: 14px;
          font-weight: normal;
          color: #4a4a4a;
        }

        .value {
          font-size: 14px;
          font-weight: 600;
          color: #4a4a4a;
        }
      }
    }
  }
}

.chip-1-high {
  .row-3 .col-1 .chip-1 {
    background-color: #EB5E5E;
  }
}

.chip-1-medium {
  .row-3 .col-1 .chip-1 {
    background-color: #EA910D;
  }
}

.chip-1-low {
  .row-3 .col-1 .chip-1 {
    background-color: #3CBE46;
  }
}

.chip-1-extreme {
  .row-3 .col-1 .chip-1 {
    background-color: black;
  }
}