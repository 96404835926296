@import "src/index.scss";

.take-action-failed-modal {
  .MuiDialogTitle-root {
    padding: 20px 10px 0px 10px;
    .title {
      justify-self: center;
      text-align: center;
      color: #4a4a4a;
      font-size: 22px;
      font-weight: 500;
      margin: 0;
    }
  }

  .take-action-failed-content {
    .sub-title {
      font-size: 16px;
      color: #4a4a4a;
      margin: $default-margin;
    }
    .details {
      font-size: 14px;
      color: #4a4a4a;
      margin: 10px 5px 0 5px;
    }
    .message {
      font-size: 14px;
      margin: $default-margin;
      color: rgb(116, 116, 116);
    }
  }

  .action-container {
    .ok-btn {
      @include default-button(white, $primary);
    }
  }
}
