@import "src/index.scss";

.action-modal {
  .MuiDialogTitle-root {
    padding: 20px 10px 0px 10px;

    .title {
      justify-self: center;
      text-align: center;
      color: #4a4a4a;
      font-size: 22px;
      font-weight: 500;
      margin: 0;
    }
  }

  .action-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: fit-content;
    background-color: white;
    width: 50vw;
    max-width: 600px;

    .question {
      margin: $default-margin;
      align-self: flex-start;
      color: #4a4a4a;
      font-weight: 500;
      font-size: 16px;
    }

    .requests {
      display: flex;
      margin: $default-margin;
      align-self: flex-start;
      color: #4a4a4a;
      font-weight: bold;
    }

    .comment-ip {
      margin: $default-margin;
      width: calc(100% - 10px);
    }

    // .comment-ip-info-label {
    //   margin: $default-margin;
    //   align-self: flex-start;
    //   color: grey;
    //   font-weight: 500;
    //   font-size: 14px;
    // }

    // .comment-ip-error{
    //   color: red;
    // }
  }

  .action-container {
    .action-btn {
      margin: $default-margin;
    }

    .cancel-btn {
      @include default-button(white, rgb(74, 74, 74));
    }

    .primary-action-btn {
      @include default-button(white, rgb(74, 74, 74));
    }
  }
}

@media only screen and (max-width: $tablet-mini) {
  .action-modal {
    .action-content {
      width: unset;
    }
  }
}

@media only screen and (max-width: $desktop ) {
  .action-modal {
    .action-content {
      .requests {
        display: inline;
        margin: $default-margin;
        align-self: flex-start;
        color: #4a4a4a;
        font-weight: bold;
      }
    }
  }
}