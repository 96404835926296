@import "src/index.scss";
.forward-status-modal {
  .forward-status-modal-content {
    display: flex;
    width: 100%;
    flex-direction: row;
    justify-content: flex-start;
    .forward-status-loading {
      .forward-status-loading-icon {
        padding-top: 40px;
        display: table;
        margin: 0 auto;
      }
      .forward-status-loading-text {
        display: table;
        margin: 0 auto;
        font-size: 14px;
        font-weight: bold;
        padding-top: 30px;
        padding-bottom: 30px;
      }
    }

    .forward-status-failed {
      .forward-status-failure-text {
        display: table;
        margin: 0 auto;
        font-size: 14px;
        font-weight: bold;
        padding-top: 90px;
        padding-right: 50px;
        padding-left: 50px;
        padding-bottom: 60px;
      }
    }
    .forward-status-success-icon {
      padding-top: 40px;
      display: table;
      margin: 0 auto;
      font-size: 1.35rem;
      color: #9B9B9B;
    }
    .forward-status-success-text {
      display: table;
      margin: 0 auto;
      font-size: 14px;
      font-weight: bold;
      padding-top: 30px;
      padding-bottom: 30px;
    }
    .reset-modal-title {
      .reset-modal-title-text {
        font-size: 24px;
        font-weight: bold;
        letter-spacing: 0;
        line-height: 38px;
      }
    }
  }
  .action-container {
    .action-btn {
      margin: $default-margin;
    }

    .save-btn {
      margin: $default-margin;
      @include default-button(white, $primary);
      
    }
  }
}
