@import "src/index.scss";

.header-5 {
  padding: 10px;
  .row-1 {
    .dot {
      margin: $default-margin;
      height: 10px;
      width: 10px;
      border-radius: 10px;
    }

    .label-1 {
      margin: $default-margin;
    }
  }
  .row-2 {
    .label-2 {
      margin: $default-margin;
    }
  }

  .row-3 {
      .chip-1 { //NOSONAR
      }
  }
}
