@import "src/index.scss";

.pr-card {
  .row-1 {
    .dot {
      background-color: $pr;
    }

    .label-1 {
      color: $pr;
      font-size: 14px;
    }

    .key-value-1 {
      .key {
        font-size: 18px;
        font-weight: 700;
        color: #4a4a4a;
      }

      .value {
        font-size: 12px;
        font-weight: normal;
        color: #9b9b9b;
        margin-top: 5px;
      }
    }
  }

  .row-2 {
    .label-2 {
      font-size: 18px;
      color: #4a4a4a;
    }
  }

  .row-4 {
    .col-1 {
      .chip-1 {
        background-color: #E7E7F2;

        .label {
          color: #4A4A4A;
        }
      }

      .label-3 {
        font-size: 14px;
        color: #83a7b2;
      }
    }
  }

  @media (min-width: 375px) and (max-width: 400px) {
    .user-details {
      max-width: 35%;
    }
  }

  @media (min-width: 1025px) {
    .user-details {
      max-width: 65%;
    }
  }


  @media (min-width: 412px) and (max-width: 1024px) {
    .user-details {
      max-width: 45%;
    }
  }

}

.chip1-hidden {
  .row-4 .chip-1 {
    display: none;
  }
}