@import "src/index.scss";

.header-1 {
  //padding: 10px;
  .row-1 {
    .dot {
      margin: $default-margin;
      height: 10px;
      width: 10px;
      border-radius: 10px;
      background-color: gray;
    }

    .label-1 {
      margin: $default-margin;
    }

    .label-2 {
      margin: $default-margin;
    }
  }

  .row-2 {
    .label-3 {
      margin: $default-margin;
    }
  }

  .row-3 {
    word-break: break-all;
    .key-value-1 {
      flex-grow: 1;
      justify-content: flex-end;
      align-items: baseline;
    }
  }
}
