@import "src/index.scss";

$systen-cell-width: calc(90px);
$systen-cell-width-mobile: calc(65px);
$systen-cell-width-fav: calc(75px);
$modal-width: calc(#{$systen-cell-width} * 6 + 230px);

$modal-width-Tablet-Mx: 80vw;

.system-selection-profile-modal {
  .note {
    display: flex;
    align-items: flex-start;
    padding: 5px;
    justify-content: center;
    .note-style {
      background-color: #f0f0f0;
      border-radius: 5px;
      border: 1px #dce1e1 solid;
      min-width: 495px;
      font-weight: 600;
      padding: 10px;
      width: 720px;
    }

    @media screen and (max-width: 767px) {
      .note-style {
        min-width: auto;
        width: 100%;
        height: auto;
        padding: 8px;
        box-sizing: border-box;
      }
    }
  }
  .system-row {
    display: flex;
    flex-direction: row;

    .system-row-container {
      position: relative;
      height: calc(#{$systen-cell-width} + 20px);
      width: calc(#{$systen-cell-width} + 20px);

      .system-cell {
        position: absolute;
        top: 20px;
        left: 20px;
        justify-content: center;
        padding: 20px;
        display: flex;
        flex-direction: column;
        border-radius: 8px;
        height: $systen-cell-width;
        width: $systen-cell-width;
        align-items: center;
        background-color: #051464;
        border: 0.8px #00d2dc solid;

        .system-img {
          height: 30px;
          width: 30px;
        }
      }

      .disable-system-cell {
        @extend .system-cell;
        background-color: rgb(195, 195, 195);
        color: #9b9b9b;
      }

      .checkbox {
        position: absolute;
        top: 0px;
        left: 0px;
        svg {
          background: #0014dc;
          border-radius: 5px;
          transform: scale(0.9);
        }
      }

      .description {
        color: white;
        margin: 5px 0 0 0;
        text-align: center;
        font-size: 14px;
      }
    }
  }
  .unchecked-system-row {
    display: flex;
    flex-direction: row;

    .system-row-container {
      position: relative;
      height: calc(#{$systen-cell-width} + 20px);
      width: calc(#{$systen-cell-width} + 20px);

      .system-cell {
        position: absolute;
        top: 20px;
        left: 20px;
        justify-content: center;
        padding: 20px;
        display: flex;
        flex-direction: column;
        border-radius: 8px;
        height: $systen-cell-width;
        width: $systen-cell-width;
        align-items: center;
        background-color: #051464;

        .system-img {
          height: 30px;
          width: 30px;
        }
      }

      .disable-system-cell {
        @extend .system-cell;
        background-color: rgb(195, 195, 195);
        color: #9b9b9b;
      }

      .checkbox {
        position: absolute;
        top: 0px;
        left: 0px;
        svg {
          background: white;
          border-radius: 5px;
        }
      }

      .description {
        color: white;
        margin: 5px 0 0 0;
        text-align: center;
        font-size: 14px;
      }
    }
  }
  .MuiCheckbox-colorSecondary.Mui-disabled {
    color: rgba(0, 0, 0, 0.26);
  }
  .refresh-section {
    padding: 5px 15px 0px 20px;
    display: flex;
    width: 100%;
    flex-direction: row;
    justify-content: flex-start;
    margin: -3px;
    .information {
      display: flex;
      align-items: center;
      color: #9b9b9b;
      font-size: 13px;
      font-style: italic;
      font-weight: 500;
      width: 100%;
      text-align: start;
      margin: $default-margin;
      .highlight-text {
        color: black;
        font-weight: 600;
        display: contents;
      }
    }

    .action-container {
      .MuiDialogActions-root {
        flex: 0 0 auto;
        display: flex;
        padding: 8px;
        padding-right: 20px;
        align-items: center;
        justify-content: flex-end;
      }
      .action-btn {
        margin: $default-margin;
      }
      .refresh-btn {
        margin: $default-margin;
        border: 1px solid #0014dc;
        @include default-button(#0014dc, white);
        white-space: nowrap;
      }
      .disabled-refresh-btn {
        border: 1px solid rgba(0, 0, 0, 0.12);
        color: rgba(0, 0, 0, 0.26);
        white-space: nowrap;
      }

      .save-btn {
        margin: $default-margin;
        @include default-button(white, #0014dc);
      }
    }
  }

  .MuiIconButton-colorSecondary {
    color: #0014dc;
  }
  .MuiPaper-root {
    width: $modal-width;
    max-width: $modal-width;
    min-width: 350px;
  }

  .title-text1-container {
    padding: 20px 15px 0px 20px;
    display: flex;
    width: 100%;
    flex-direction: row;
    justify-content: flex-start;

    .titleText {
      color: #4a4a4a;
      margin: $default-margin;
      font-size: 24px;
      font-weight: bold;
    }
    .closeButton {
      justify-content: flex-end;
    }
  }

  .user-system-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    background-color: white;
    overflow: auto;

    .user-system-profile-box {
      display: flex;
      align-items: stretch;
      min-height: 626px;
    }

    .preferred-system {
      // min-height: 668px;
      // padding: 10px;
      min-height: 626px;
      width: 25%;
      height: 100%;
      background: #051464;
      border-radius: 10px;
      border: 0.5px solid rgb(0, 20, 220);
    }

    .unchecked-system-container {
      border: 1px #afbee1 solid;
      display: flex;
      flex-wrap: wrap;
      justify-content: start;
      gap: 20px;
      padding: 10px;
      background: #f7f9fc;
      border-radius: 10px;
      align-content: start;
      flex-direction: row;
      width: 100%;
      height: auto;
      align-items: flex-start;
      .loading-container {
        position: absolute; /* Position relative to the parent .unchecked-system-container */
        top: 50%; /* Center vertically */
        left: 60%; /* Center horizontally */
        transform: translate(-50%, -50%); /* Adjust to center precisely */
      }
      .available-systems-label {
        display: flex;
        flex-direction: column;
        width: 100%;
      }
      .access-label {
        margin: $default-margin;
        color: #4a4a4a;
        font-size: 20px;
        font-family: "SLB Sans", Arial, sans-serif;
        font-weight: 700;
        word-wrap: break-word;
      }
    }
    .information-unchecked {
      color: #9b9b9b;
      font-size: 16px;
      font-style: italic;
      font-weight: 500;
      width: 100%;
      text-align: start;
      margin: -10px;
      padding: 5px 15px;
      .font-style {
        color: #0014dc;
        font-weight: 700;
      }
    }
    .divider-1 {
      margin-top: 10px;
      width: 100%;
    }

    .divider-2 {
      margin-top: 10px;
      width: 100%;
    }

    .information {
      color: #9b9b9b;
      font-size: 16px;
      font-style: italic;
      font-weight: 500;
      text-align: start;
      margin: 3px;
    }

    .title-text-container {
      display: flex;
      flex-direction: row;
      align-items: center;
      flex-wrap: wrap;
      width: 100%;
      justify-content: center;
      // margin: $default-margin;

      .access-label {
        margin: $default-margin;
        color: #00d2dc;
        font-size: 20px;
        font-family: "SLB Sans", Arial, sans-serif;
        font-weight: 700;
        word-wrap: break-word;
        display: flex;
        align-items: baseline;
      }

      .refresh-btn {
        margin: $default-margin;
        border: 1px solid #0014dc;
        @include default-button(#0014dc, white);
      }
      .disabled-refresh-btn {
        border: 1px solid rgba(0, 0, 0, 0.12);
        color: rgba(0, 0, 0, 0.26);
      }
      .checked-system-container {
        justify-content: center;
        display: flex;
        flex-wrap: wrap;
        width: 100%;
        padding-bottom: 18px;
        padding-right: 20px;
        .space {
          padding-top: 20px;
          padding-left: 20px;

          .empty-container {
            width: 90px;
            height: 90px;
            padding: 10px;
            background: #051464;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            gap: 5px;
            display: inline-flex;
            border-radius: 8px;
            border: 1px #afbee1 dotted;
          }
        }
      }
    }
  }

  .confirmation-container {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    padding-right: 20px;
  }
  .colored-text {
    color: #051464;
    font-family: "SLB Sans", Arial, sans-serif;
    font-size: 12px;
    font-style: italic;
    font-weight: 700;
    line-height: normal;
  }

  .confirmation-text {
    color: #9b9b9b;
    text-align: right;
    font-family: "SLB Sans", Arial, sans-serif;
    font-size: 12px;
    font-style: italic;
    font-weight: 500;
    line-height: normal;
  }
  .confirmation-text-2 {
    color: #9b9b9b;
    text-align: right;
    font-family: "SLB Sans", Arial, sans-serif;
    font-size: 12px;
    font-style: italic;
    font-weight: 500;
    line-height: normal;
  }

  .action-container {
    .action-btn {
      margin: $default-margin;
    }

    .save-btn {
      margin: $default-margin;
      @include default-button(white, #0014dc);
    }
  }

  .remove-icon {
    color: white;
  }
}

@media only screen and (max-width: $tablet-mini) {
  .user-system-profile-modal {
    .MuiPaper-root {
      width: $modal-width-Tablet-Mx;
      max-width: $modal-width-Tablet-Mx;
    }
  }
}

@media only screen and (max-width: $mobile) {
  .system-selection-profile-modal {
    .preferred-system {
      min-width: 90px;
    }
    .note {
      display: flex;
      align-items: flex-start;

      .note-style {
        background-color: #f0f0f0;
        min-width: 495px;
        font-weight: 600;
        padding: 4px;
        border-radius: 5px;
        border: 1px #dce1e1 solid;
      }

      @media screen and (max-width: 480px) {
        .note-style {
          min-width: auto;
          width: 90%;
          height: auto;
          box-sizing: border-box;
        }
      }
    }

    .refresh-section {
      padding: 1px;
      display: flex;
      width: 100%;
      flex-direction: row;
      justify-content: flex-start;
      .information {
        color: #9b9b9b;
        font-size: 10px;
        font-style: italic;
        font-weight: 500;
        width: 100%;
        text-align: start;
        .highlight-text {
          color: black;
          font-weight: 600;
          display: contents;
        }
      }

      .action-container {
        .action-btn {
          margin: $default-margin;
        }
        .refresh-btn {
          width: 94px !important;
          font-size: 8px !important;
          margin: $default-margin;
          border: 1px solid #0014dc;
          @include default-button(#0014dc, white);
          white-space: nowrap;
        }
        .disabled-refresh-btn {
          border: 1px solid rgba(0, 0, 0, 0.12);
          color: rgba(0, 0, 0, 0.26);
          white-space: nowrap;
        }

        .save-btn {
          margin: $default-margin;
          @include default-button(white, #0014dc);
        }
      }
    }

    .user-system-content {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 100%;
      background-color: white;
      overflow: auto;

      .user-system-profile-box {
        display: flex;
        align-items: stretch;
      }

      .preferred-system {
        // min-height: 668px;
        // padding: 10px;
        width: 25%;
        height: 100%;
        background: #051464;
        border-radius: 10px;
        border: 0.5px solid rgb(0, 20, 220);

        .information {
          color: #9b9b9b;
          font-size: 16px;
          font-style: italic;
          font-weight: 500;
          text-align: start;
          margin: 3px;
        }
      }

      .unchecked-system-container {
        border: 1px #afbee1 solid;
        display: flex;
        flex-wrap: wrap;
        justify-content: start;
        gap: 5px;
        padding: 5px;
        background: #f7f9fc;
        border-radius: 10px;
        align-content: start;
        min-width: 195px;
        overflow: scroll;
        flex-direction: row;
        width: 100%;
        height: auto;
        align-items: flex-start;
        .loading-container {
          position: absolute; /* Position relative to the parent .unchecked-system-container */
          top: 50%; /* Center vertically */
          left: 63%; /* Center horizontally */
          transform: translate(-50%, -50%); /* Adjust to center precisely */
        }
        .available-systems-label {
          display: flex;
          flex-direction: column;
          width: 100%;
        }
        .access-label {
          margin: $default-margin;
          color: #4a4a4a;
          font-size: 20px;
          font-family: "SLB Sans", Arial, sans-serif;
          font-weight: 700;
          word-wrap: break-word;
        }
      }
      .information-unchecked {
        color: #9b9b9b;
        font-size: 12px;
        font-style: italic;
        font-weight: 500;
        width: 100%;
        text-align: start;
        margin: -10px;
        padding: 5px 15px;
        .font-style {
          color: #0014dc;
          font-weight: 700;
        }
      }
      .divider-1 {
        margin-top: 10px;
        width: 100%;
      }

      .divider-2 {
        margin-top: 10px;
        width: 100%;
      }

      .information {
        color: #9b9b9b;
        font-size: 16px;
        font-style: italic;
        font-weight: 500;
        text-align: start;
        margin: 3px;
      }

      .title-text-container {
        display: flex;
        flex-direction: row;
        align-items: center;
        flex-wrap: wrap;
        width: 100%;
        justify-content: center;
        // margin: $default-margin;

        .access-label {
          margin: $default-margin;
          color: #00d2dc;
          font-size: 12px;
          font-family: "SLB Sans", Arial, sans-serif;
          font-weight: 700;
          word-wrap: break-word;
          display: flex;
          align-items: baseline;
        }

        .refresh-btn {
          margin: $default-margin;
          border: 1px solid #0014dc;
          @include default-button(#0014dc, white);
        }
        .disabled-refresh-btn {
          border: 1px solid rgba(0, 0, 0, 0.12);
          color: rgba(0, 0, 0, 0.26);
        }
        .checked-system-container {
          justify-content: center;
          display: flex;
          flex-wrap: wrap;
          width: 100%;
          padding-bottom: 18px;
          padding-right: 20px;
          gap: 2px;

          .space {
            padding-top: 20px;
            padding-left: 20px;

            .empty-container {
              width: 60px;
              height: 60px;
              padding: 10px;
              background: #051464;
              flex-direction: column;
              justify-content: center;
              align-items: center;
              gap: 5px;
              display: inline-flex;
              border-radius: 8px;
              border: 1px #afbee1 dotted;
            }
          }
        }
      }
    }

    .unchecked-system-row {
      display: flex;
      flex-direction: row;

      .system-row-container {
        position: relative;
        height: calc(#{$systen-cell-width-mobile} + 20px);
        width: calc(#{$systen-cell-width-mobile} + 20px);

        .system-cell {
          position: absolute;
          top: 20px;
          left: 20px;
          justify-content: center;
          padding: 20px;
          display: flex;
          flex-direction: column;
          border-radius: 8px;
          height: $systen-cell-width-mobile;
          width: $systen-cell-width-mobile;
          align-items: center;
          background-color: #051464;

          .system-img {
            height: 20px;
            width: 20px;
          }
        }

        .disable-system-cell {
          @extend .system-cell;
          background-color: rgb(195, 195, 195);
          color: #9b9b9b;
        }

        .checkbox {
          position: absolute;
          top: 0px;
          left: 0px;
          svg {
            background: white;
            border-radius: 5px;
          }
        }

        .description {
          color: white;
          margin: 5px 0 0 0;
          text-align: center;
          font-size: 10px;
        }
      }
    }

    .system-row {
      display: flex;
      flex-direction: row;

      .system-row-container {
        position: relative;
        height: calc(#{$systen-cell-width-mobile} + 20px);
        width: calc(#{$systen-cell-width-mobile} + 20px);

        .system-cell {
          position: absolute;
          top: 20px;
          left: 20px;
          justify-content: center;
          padding: 20px;
          display: flex;
          flex-direction: column;
          border-radius: 8px;
          height: $systen-cell-width-mobile;
          width: $systen-cell-width-mobile;
          align-items: center;
          background-color: #051464;
          border: 0.8px #00d2dc solid;

          .system-img {
            height: 20px;
            width: 20px;
          }
        }

        .disable-system-cell {
          @extend .system-cell;
          background-color: rgb(195, 195, 195);
          color: #9b9b9b;
        }

        .checkbox {
          position: absolute;
          top: 0px;
          left: 0px;
          svg {
            background: #0014dc;
            border-radius: 5px;
            transform: scale(0.9);
          }
        }

        .description {
          color: white;
          margin: 5px 0 0 0;
          text-align: center;
          font-size: 10px;
        }
      }
    }
  }
}
