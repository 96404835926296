@import "src/index.scss";
.pretrip-list-container {
  display: flex;
  flex-direction: column;
  height: 100%;

  .pretrip-progress{
    justify-self: center;
    align-self: center;
  } 

  .message-container{
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    .pretrip-loading {
      width: 30px;
      height: 30px;
    }
  }
  
  .action-container {
    width: calc(100% - 20px);
    height: 100px;
    display: flex;
    flex-direction: row;
    padding: 10px 10px;
    margin: 0 10px;
    justify-content: center;
    align-items: center;
    border-radius: 0 0 15px 15px;
    background-color: rgba(0, 0, 0, 0.1);
  }
}

@media only screen and (max-width:$mobile-mini) {
  .pretrip-list-container {
    .MuiGrid-container{
      flex-wrap: nowrap;
    }
  }
}

