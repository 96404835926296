.jra-table {
    .MuiCard-root {
        visibility: visible;
    }

    margin-bottom: 20px;

    .table-header-row {
        border: 1px solid rgb(224, 224, 224);
        background-color: rgba(202, 203, 239, 0.33);
    }

    .table-header {
        color: #4a4a4a;
        font-size: 12px;
        font-weight: 300;
        letter-spacing: 0;
        line-height: 14px;
    }

    .data-row {
        border: 0.5px solid lightgrey;
    }

}