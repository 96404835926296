.detailsTab {
  height: 100%;
  display: flex;
  flex-direction: column;

  .tabComponent {
    border-bottom: 2px solid #9797973d;
    display: flex;
    flex-direction: row;
    max-width: 100%;
    overflow-x: auto;
    min-height: 52px;

    .tab {
      display: flex;
      flex-direction: row;
      padding: 5px;
      cursor: pointer;
      flex: 1;
      justify-content: center;
      align-items: center;
      max-width: 250px;
      height: 50px;
      background-color: white;
      border-bottom: 2px solid transparent;
    }

    .selected-tab {
      background-color: #f0f9ff;
      border-bottom: 2px solid #0014DC;
    }

    .tab-img{
      width: 30px;
      height: 30px;
    }

    .selected-tab-img{
      background-color: #F0F9FF;
    }

    .tab-name {
      color: #4a4a4a;
      font-size: 14px;
      font-weight: 500;
      margin: 5px;
    }
  
    .selected-tab-name{
      color: #0014DC
    }

    .tab-chip {
      height: 22px;
      border-radius: 12px;
      margin: 5px;
    }

    .selected-tab-chip{
      background-color: #0014DC;
      color: white;
    }
  }
  .overflow{
    overflow: scroll;
  }
}
