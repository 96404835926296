@import "src/index.scss";

.po-items-tab {
  margin-top: 20px;

  .no-po-items {
    color: #9b9b9b;
    display: flex;
    justify-content: center;
    margin: 5px;
    font-weight: 600;
    padding-top: 30px;

    p {
      margin: 0;
    }
  }

  .eclaims-item-rows {
    .spacing {
      height: 12px;
    }

    // Mixin for po-items-row
    @mixin po-items-row() {
      border: 0.5px solid #979797;
      background: #e8e8f8;
    }

    .eclaims-items-row-1 {
      @include po-items-row();
    }

    .eclaims-items-row {
      border: 0.5px solid #979797;
      background: #e8e8f8;

      .eclaims-items-row-text-1 {
        font-size: 12px;
        font-weight: bold;
        margin: 0;
      }

      .key-value-1 {
        .key {
          font-size: 12px;
          font-weight: bold;
          color: #4a4a4a;
        }

        .value {
          font-size: 12px;
          font-weight: bold;
          color: #9b9b9b;
        }
      }
    }
  }

  .detail-row {
    .MuiTableCell-root {
      padding: 0px;
      border-bottom: none;
    }

    .MuiAccordion-root {
      box-shadow: none;

      .MuiAccordion-root.Mui-expanded {
        margin: 0px;
      }

      .MuiAccordionSummary-root.Mui-expanded {
        min-height: auto;
      }

      .MuiAccordionSummary-root {
        // padding: 0px;

        .MuiAccordionSummary-content {
          margin: 0px;

          .header-content {
            color: #6d7296;
            font-size: 15px;
            font-weight: 600;
            letter-spacing: 0;
            line-height: 19px;
            display: flex;
          }
          .headerCount {
            color: #6d7296;
            font-size: 15px;
            font-weight: 600;
            letter-spacing: 0;
            line-height: 19px;
            display: flex;
            float: right;
            border-radius: 13px;
          }
        }
      }

      .MuiAccordionDetails-root {
        padding: 8px 0px 16px;

        .border-div {
        }
      }
    }

    .attendees-table {
      .MuiTableCell-root {
        padding: 16px 6px 16px 16px;
        color: #000000;
        font-size: 12px;
        font-weight: 400;
        letter-spacing: 0;
        line-height: 16px;
      }

      .custom-table .table-row .table-cell .header-cell-content .header-label {
        font-size: 12px;
        color: #7c7c7c;
        min-width: 50px;
      }

      th {
        padding: 16px 2px 2px 16px;
      }
    }
  }
}
