@mixin dlp-items-row() {
  border: 1px solid rgba(224, 224, 224, 1);
}

.dlp-loadfactor-row {
  margin-top: 20px!important;
  @include dlp-items-row();
  .split-item-details {
    padding: 0px;
  }
}

.dlp-risk-row{
  margin-top: 20px!important;
  @include dlp-items-row();
  .split-item-details {
    padding: 0px;
  }
}
