@import "src/index.scss";
.approve-buddy-2 {
  position: relative;

  * {
    font-family: "SLBSans", "Roboto", "Helvetica Neue", sans-serif !important;
    scrollbar-width: none !important;
  }
}

.po-error-box {
  border-radius: 4px; /* Rounded corners */
  margin-top: 20px;

  .try-again{
    margin-bottom: 15px;
    .refresh-system{
      color: #0010c5;
      display: inline;
    }
  }
  .steps-content{
    text-align: left;
    .steps-title{
      margin-bottom: 10px;
    }
    .steps{
      color : #666666;
      margin-bottom: 20px;
    }
  }

  .not-working-text {
    width: 340px;
    margin-top: 15px;
  }

  .virtual-assistant {
    display: inline;
    color: #0010c5;
    font-weight: 600;
    .bot-icon{
      vertical-align: middle;
    }
  }

  .action-container {
    .action-btn {
      margin: 5px;
    }

    .refresh-system-button {
      margin: 5px;
      border-radius: 4px; 
      color: white;
      border: 1px #0014DC solid;
      padding: 10px 20px 10px 20px;
      background-color: #0014dc !important;
      font-size: 14px;
      cursor: pointer;
      &:hover{
        background-color: darken(#0014dc, 10%);
      }
      &:active{
        background-color: darken(#0014dc, 20%);
      }
      &:disabled{
        cursor: not-allowed;
        background-color: lightgray;
      }
    }
  }
}

@font-face {
  font-family: "SLBSans";
  src: local("SLBSans-Bold"), url("./fonts/SLBSans-Bold.otf") format("opentype");
  font-weight: 700;
  font-display: swap;
}

@font-face {
  font-family: "SLBSans";
  src: local("SLBSans-Regular"),
    url("./fonts/SLBSans-Regular.otf") format("opentype");
  font-weight: 400;
  font-display: swap;
}

@font-face {
  font-family: "SLBSans";
  src: local("SLBSans-Light"),
    url("./fonts/SLBSans-Light.otf") format("opentype");
  font-weight: 300;
  font-display: swap;
}

@media only screen and (min-width: $tablet-mini) {
  .approve-buddy-2 {
    .app-content {
      .app-content-ticker {
        display: none;
      }
    }
  }
}

@media only screen and (min-width: 400px) and (max-width: $mobile) {
  .po-error-box {
    font-size: 13px;
    border-radius: 4px; /* Rounded corners */
    margin-top: 20px;
  
    .try-again{
      margin-bottom: 15px;
      max-width: 300px;
      margin-left: 20px;
      .refresh-system{
        color: #0010c5;
        display: inline;
      }
    }
    .steps-content{
      text-align: left;
      margin-left: 20px;
      .steps-title{
        margin-bottom: 10px;
      }
      .steps{
        color : #666666;
        margin-bottom: 20px;
        font-size: 12px;
      }
    }
  
    .not-working-text {
      width: 340px;
      margin-top: 15px;
      font-size: 14px;
    }
  
    .virtual-assistant {
      display: inline;
      color: #0010c5;
      font-weight: 600;
      .bot-icon{
        vertical-align: middle;
      }
    }
  
    .action-container {
      .action-btn {
        margin: 5px;
      }
  
      .refresh-system-button {
        margin: 5px;
        border-radius: 4px; 
        color: white;
        border: 1px #0014DC solid;
        padding: 10px 20px 10px 20px;
        background-color: #0014dc !important;
        font-size: 14px;
        cursor: pointer;
        &:hover{
          background-color: darken(#0014dc, 10%);
        }
        &:active{
          background-color: darken(#0014dc, 20%);
        }
        &:disabled{
          cursor: not-allowed;
          background-color: lightgray;
        }
      }
    }
  }
}

@media only screen and (max-width: 400px) {
  .po-error-box {
    font-size: 13px;
    border-radius: 4px; /* Rounded corners */
    margin-top: 20px;
    margin-left: 2px !important;
  
    .try-again{
      margin-bottom: 15px;
      max-width: 300px;
      max-width: 200px;
      .refresh-system{
        color: #0010c5;
        display: inline;
      }
    }
    .steps-content{
      text-align: left;
      max-width: 200px;
      .steps-title{
        margin-bottom: 10px;
      }
      .steps{
        color : #666666;
        margin-bottom: 20px;
        font-size: 12px;
      }
    }
  
    .not-working-text {
      max-width: 200px;
      width: 340px;
      margin-top: 15px;
      font-size: 14px;
    }
  
    .virtual-assistant {
      display: inline;
      color: #0010c5;
      font-weight: 600;
      .bot-icon{
        vertical-align: middle;
      }
    }
  
    .action-container {
      .action-btn {
        margin: 5px;
      }
  
      .refresh-system-button {
        margin: 5px;
        border-radius: 4px; 
        color: white;
        border: 1px #0014DC solid;
        padding: 10px 20px 10px 20px;
        background-color: #0014dc !important;
        font-size: 14px;
        cursor: pointer;
        &:hover{
          background-color: darken(#0014dc, 10%);
        }
        &:active{
          background-color: darken(#0014dc, 20%);
        }
        &:disabled{
          cursor: not-allowed;
          background-color: lightgray;
        }
      }
    }
  }
}
