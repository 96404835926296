@import "src/index.scss";

.take-action-reference-modal {
  .MuiDialogTitle-root {
    padding: 20px 10px 0px 10px;

    .title {
      justify-self: center;
      text-align: center;
      color: #9b9b9b;
      font-family: "SLB Sans", Arial, sans-serif;
      font-size: 22px;
      font-weight: 700;
      line-height: 22px;
      margin: 0;
    }
  }

  .action-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: fit-content;
    background-color: white;
    width: 40vw;
    max-width: 480px;
    .subtitle {
      margin-top: 0.5rem;
      color: #000;
      text-align: center;
      font-family: "SLB Sans", Arial, sans-serif;
      font-size: 18px;
      font-style: normal;
      font-weight: 500;
      line-height: 100%; /* 14px */
      letter-spacing: -0.086px;
    }
    .details {
      margin-top: 1rem;
      color: var(--SLB-Grey-5, #696969);
      text-align: center;
      font-family: "SLB Sans", Arial, sans-serif;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 100%; /* 14px */
      letter-spacing: -0.086px;
    }
  }

  .action-container {
    .action-btn {
      margin: $default-margin;
    }

    .cancel-btn {
      @include default-button(white, $primary);
    }
  }
}

@media only screen and (max-width: $tablet-mini) {
  .take-action-reference-modal {
    .action-content {
      width: unset;
    }
  }
}
