.errorDiv {
    margin-left: auto;
    display: block;
    margin-right: auto;
    // margin-bottom: 150px;
    margin-top: auto;
    margin-bottom: auto;

    @mixin errorText($color) {
        text-align: center;
        font-size: 22px;
        overflow-wrap: break-word;
        color: $color;
    }

    .errorCard {
        margin-bottom: 2%;

        .errorImage {
            width: 90%;
            padding-top: 2%;
            margin-left: auto;
            margin-right: auto;
            display: block;
        }

        .errorName {
            @include errorText(#FFFFFF);
            margin-top: 20px;
        }

        .namecontainer {
            display: flex;
            justify-content: center;
        }
    }

    .errorHelperText {
        @include errorText(#C9F3FC);
        margin-top: 40px;
    }

}

@media(max-width:600px) {
    .errorDiv {
        width: 80%;

        .errorCard {
            margin-bottom: 5%;

            .errorName {
                font-size: 18px;
                //padding-bottom: 5%;
            }
        }

        .errorHelperText {
            font-size: 18px;
        }
    }
}

.refreshcontainer {
    display: flex;
    justify-content: center;
    margin-top: 30px;

    .buttonRefresh {
        color: #FFFFFF;
        border: 1px solid #ABCEF8;
        border-radius: 10px;
        background-color: #0014DC;
        text-transform: none;
        padding: 5px 35px;
        font-size: 17px;
    }

}