@import "src/index.scss";

.card-3 {
  padding: 10px;

  .row-1 {
    .dot {
      margin: $default-margin;
      height: 10px;
      width: 10px;
      border-radius: 10px;
    }

    .label-1 {
      margin: $default-margin;
    }

  }

  .row-2 {
    .label-2 {
      margin: $default-margin;
    }
  }

  .row-4 {
    flex-wrap: nowrap;
    .col-1 {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      justify-content: center;


      .label-3 {
        margin: $default-margin;
      }
    }
  }
}