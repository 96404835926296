@import "src/index.scss";

.jra-card {
  .row-1 {
    .dot {
      background-color: $jra;
    }

    .label-1 {
      font-size: 14px;
      color: $jra;
    }

    .chip-1 {
      background-color: #3CBE46;

      .label {
        color: white;
      }
    }
  }

  .row-2 {
    .label-2 {
      font-size: 18px;
      color: #4a4a4a;
    }
  }


  .row-4 {
    .col-1 {

      .label-3 {
        font-size: 14px;
        color: #83a7b2;
      }
    }
  }





}

.chip-1-high {
  .row-1 .chip-1 {
    background-color: #EB5E5E;
  }
}

.chip-1-medium {
  .row-1 .chip-1 {
    background-color: #EA910D;
  }
}

.chip-1-low {
  .row-1 .chip-1 {
    background-color: #3CBE46;
  }
}