@import "src/index.scss";

$total-width-available: calc(
  100% - #{$default-padding}* 2 - #{$default-margin}* 4
);
$list-view-width: calc(#{$total-width-available} * 0.37);

@keyframes marquee {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(-100%);
  }
}

.ticker-box {
  display: flex;

  .box {
    padding-top: 8px;
    display: flex;
    margin: $default-margin;
    height: calc(100% - #{$default-margin}* 4);
    overflow: hidden;

    .box-content {
      overflow: hidden; // Hide the overflowing content
      white-space: nowrap;
      margin-right: 18px;
      width: 100%;
      height: 100%;
      padding: 10px 15px;
      background: #0014dc;
      border-radius: 5px;
      border: 0.5px #00d2dc solid;
      justify-content: flex-start;
      align-items: center;
      gap: 30px;
      display: inline-flex;

      .box-text {
        &:hover .span-text {
          animation-play-state: paused;
        }
      }

      .span-text {
        color: white;
        font-size: 14px;
        font-weight: 500;
        word-wrap: break-word;
        // display: inline-block;

        .click-here {
          color: #00d2dc;
          font-size: 14px;
          font-style: italic;
          font-weight: 700;
          word-wrap: break-word;
          a {
            color: inherit; // This ensures the anchor tag takes the color of its parent
            text-decoration: underline; // This removes the underline

            &:hover,
            &:focus,
            &:active {
              color: inherit; // Keeps the color consistent even on hover, focus, or active states
              text-decoration: underline; // If you want the underline to appear on hover, focus, or active states
            }
          }
        }
      }
    }
  }
}

// Media query for screens below 600px
@media only screen and (max-width: 600px) {
  .ticker-box {
    .box {
      width: 100%;
    }
  }
}
