@import "src/index.scss";

.card-skeleton-1{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 10px;
    
    .default-margin{
        margin : $default-margin;
    }

    .row-1{
        display: flex;
        flex-direction: row;
        width: 100%;
        margin-top: 20px;
        align-items: center;

        .column-1{
            display: flex;
            flex-direction: column;
            flex-grow: 1;
        }
    }
}