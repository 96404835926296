@import "src/index.scss";

.pretrip-approvers-tab {
  margin-top: 20px;

  .no-approvers {
    color: #9b9b9b;
    display: flex;
    justify-content: center;
    margin: $default-margin;
    font-weight: bold;
  }

  .main-row {
    border: 1px solid rgba(224, 224, 224, 1);
  }

  .spacing {
    height: 12px;
  }

  .approver-name {
    font-weight: 600;
  }


  .approved {
    color: #56a402;
    font-weight:600;
  }

  .rejected {
    color: #EB5E5E;
    font-weight:600;
  }

  .pending {
    color: #9b9b9b;
    font-weight:600;
  }
}