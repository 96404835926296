.main-container {
  
  

  .title-container {
    color: #4a4a4a;
    border: 1px solid #e0e0e0;
    background-color: rgba(202, 203, 239, 0.33);
    margin-top: 20px;
    .quest-section-title {
      font-size: 14px;
      margin: 15px 0;
      padding-left: 10px;
      color: #7c7c7c;
      padding-right: 5px;
    }
  }

  .subtitle-container {
    border: 1px solid #e0e0e0;

    .quest-details-row-title {
      padding-top: 5px;
      font-size: 14px;
      margin: 15px 0;
      padding-left: 10px;
      color: #9b9b9b;
      background-color: white !important;
      padding-right: 5px;
    }
    .quest-details-row-answer{
      font-size: 14px;
      margin: 15px 0;
      padding-left: 10px;
      font-weight: 600;
      background-color: white !important;
      padding-right: 5px;
    }
    .quest-details-row-answer:not(:last-child){
      border-bottom: 1px solid #e0e0e0;
      padding-bottom: 15px;
    }
  }
}
