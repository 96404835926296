@import "src/index.scss";

.card-2 {
  padding: 10px;

  .row-1 {
    .dot {
    margin: $default-margin;
      height: 10px;
      width: 10px;
      border-radius: 10px;
      background-color: gray;
    }

    .label-1{
      margin: $default-margin;
      font-size: 14px;
      color: gray;
    }

    
  }

  .row-2 {
    .label-2 {
      margin: $default-margin;
    }
  }

  

  .row-4 {
    flex-wrap: nowrap;
    .label-3 {
      margin: $default-margin;
    }
  }
}
