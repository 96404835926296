.ej-details-card {
  display: flex;
  flex-direction: column;
  background-color: white;
  height: 100%;
  width: 100%;

  .message-container{
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    .message-1{
      .title{ 
        color: darkgray;
      }
      .sub-title{
        color: darkgray;
      }
    }
  }

  .ej-details-header {
    padding: 20px 20px 0 20px;

    .dividerDiv {
      box-sizing: border-box;
      height: 1px;
      border: 1px solid #979797;
      opacity: 0.23;
      margin-top: 10px;
    }
  }

  .ej-details-content {
    padding: 0 20px 20px 20px;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    overflow: auto;
  }

  .action-container {
    background-color: #e9ebf5;
    padding: 10px 20px;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
  }
}
