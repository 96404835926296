@import "src/index.scss";

.no-attachment {
    color: #9b9b9b;
    display: flex;
    justify-content: center;
    margin: $default-margin;
    font-weight: bold;
    padding-top: 30px;
}

.attachment-list {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    width: 100%;
    margin: 20px;
}