.compliance-sec {
    border: 1px solid #e0e0e0;
    margin-bottom: 20px;

    .panelheader {
        background-Color: #e9ebf5;
        padding: 16px;
        padding-bottom: 15px;
        color: black;

        .panelheadertext {
            font-size: 16px;
            margin-bottom: 10px;
        }
    }

    .compliance-text {
        position: absolute;
    }

    .compliance-header {
        float: right
    }

    .compliance-container {
        margin-left: 10px;
        margin-top: 10px;

        .complianceRow {
            margin-bottom: 10px;
        }
    }

    .compliance-row {
        vertical-align: top;
    }

    .compliance-status {
        padding: 5px 8px 5px 0px;
        align-items: center;
        font-size: 16px;
        font-weight: 600;
        letter-spacing: 0;
        line-height: 19px;
        color: black;

        .noncompliance {
            color: #EB5E5E;
        }

        .compliance {
            color: #3CBE46;
        }
    }
}