@import "src/index.scss";

$total-width-available: calc(
  100% - #{$default-padding}* 2 - #{$default-margin}* 4
);

.app-header {
  height: $app-header-height;
  background: #0014dc;
  display: flex;

  .logo-main {
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    padding-left: 25px;
    justify-content: center;
    align-items: flex-start;
  }

  .header-ticker {
    width: calc((#{$total-width-available}) * 0.65 - 120px);
  }

  .AB-logo-container {
    width: $app-sidebar-width;
    background-color: #051464;
    justify-content: center;
    align-items: center;
    display: flex;


  }

  .user-image {
    flex-direction: row;
    justify-content: center;
    align-items: center;
    display: flex;
    width: 80px;

    div.icon-image > div > div {
      display: flex;
      justify-content: center;
      align-items: center;

      img {
        cursor: pointer;
      }
    }
  }

  .logo-image {
    height: 32px;
    width: auto;
    margin-bottom: 2px;
  }

  .heading {
    font-size: 20px;
    display: flex;
    line-height: 20px;

    .approve {
      color: white;
      font-size: 30px;
      font-weight: 500;
    }

    .buddy {
      color: white;
      font-size: 30px;
      font-weight: 500;
    }
  }
}

.menu-appbar {
  top: 3rem;
  right: 3rem;

  .menu-item-header {
    padding: 15px;
    pointer-events: none;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    min-width: 254px;
    background: #051464;

    .login {
      padding-bottom: 4%;
      font-size: 12px;
      color: #00d2dc;
      font-family: "SLB Sans", Arial, sans-serif;
      font-style: normal;
      font-weight: 450;
      line-height: 13px;
    }

    .name {
      display: block;
      flex-direction: column;
      overflow-wrap: break-word;
      color: rgb(255, 255, 255);
      font-size: 18px;
    }

    .role {
      color: rgb(255, 255, 255);
      font-size: 14px;
    }
  }

  .refresh {
    color: rgb(253, 81, 102);
    cursor: pointer;
  }

  .disabled-refresh {
    pointer-events: none;
    opacity: 0.5;
  }

  .logout {
    color: rgb(253, 81, 102);
  }
}

@media only screen and (max-width: 375px) {
  .app-header {
    .AB-logo-container {
      width: 100px;
    }
  }
}

@media only screen and (width: 390px) {
  .app-header {
    .AB-logo-container {
      width: 80px;
    }
  }
}

@media only screen and (width: 393px) {
  .app-header {
    .AB-logo-container {
      width: 79px;
    }
  }
}

@media only screen and (width: 412px) {
  .app-header {
    .AB-logo-container {
      width: 80px;
    }
  }
}

@media only screen and (width: 414px) {
  .app-header {
    .AB-logo-container {
      width: 79px;
    }
  }
}

@media only screen and (width: 360px) {
  .app-header {
    .AB-logo-container {
      padding: 20px;
      width: 80px;
    }
  }
}

//Galaxy S9+
@media only screen and (width: 320px) {
  .app-header {
    .logo-main {
      padding-left: 4px;
    }

    .heading {
      .approve {
        color: white;
        font-size: 25px;
        font-weight: 500;
      }

      .buddy {
        color: white;
        font-size: 25px;
        font-weight: 500;
      }
    }
  }
}

//LG Optimus L70
@media only screen and (width: 384px) {
  .app-header {
    .logo-main {
      padding-left: 5px;
    }

    .heading {
      .approve {
        color: white;
        font-size: 25px;
      }

      .buddy {
        color: #4a90e2;
        font-size: 25px;
      }
    }
  }
}

@media only screen and (max-width: $tablet) {
  $total-width-available: calc(
    100% - #{$default-padding}* 2 - #{$default-margin}* 4
  );
  .app-header {
    .header-ticker {
      width: calc(#{$total-width-available} * 0.6 - 110px);
    }
  }
}

@media only screen and (max-width: $tablet-mini) {
  $total-width-available: calc(
    100% - #{$default-padding}* 2 - #{$default-margin}* 4
  );
  .app-header {
    .header-ticker {
      display: none;
    }
  }
}
