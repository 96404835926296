.jra-details-card {
    display: flex;
    flex-direction: column;
    background-color: white;
    height: 100%;
    width: 100%;

    .message-container {
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;

        .message-1 {
            .title {
                color: darkgray;
            }

            .sub-title {
                color: darkgray;
            }
        }
    }

    .jra-details-header {
        padding: 20px 20px 0 20px;

        .dividerDiv {
            box-sizing: border-box;
            height: 1px;
            border: 1px solid #979797;
            opacity: 0.23;
            margin-top: 10px;
        }
    }

    .jra-details-content {
        padding: 0 20px 20px 20px;
        display: flex;
        flex-direction: column;
        flex-grow: 1;
        overflow: auto;
    }

    .action-container {
        background-color: #e9ebf5;
        padding: 10px 20px;
        display: grid;
        flex-direction: row;
        justify-content: center;
        align-items: center;

        .link-Text {
            color: #9b9b9b;
            font-size: 16px;
            font-weight: 500;
            letter-spacing: 0;
            line-height: 21px;
            padding-left: 10px;
            word-break: normal;
            margin-top: 5px;
        }

        .link {
            color: #0014DC;
            font-weight: 700;
            cursor: pointer;
            text-decoration: none;
        }
    }

    @media screen and (min-width: 900px) {
        .action-container {
            display: flex;
            justify-content: flex-end;
        }
    }



    .approve-reject-modal {
        margin: 5px;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
    }

    @media screen and (min-width: 900px) {
        .approve-reject-modal {
            width: fit-content;
            width: -moz-fit-content;
        }
    }


}

@media (min-width: 200px) {
    .jra-details-card {
        .approve-reject-modal .reject-btn {
            padding: 5px 22px;
            margin-left: 8px;
        }

        .approve-reject-modal .approve-btn {
            padding: 5px 20px;
        }
    }
}

@media (min-width: 375px) and (max-width: 400px) {
    .jra-details-card {
        .approve-reject-modal .reject-btn {
            padding: 5px 50px;
            margin-left: 14px;
        }

        .approve-reject-modal .approve-btn {
            padding: 5px 50px;
        }
    }
}

@media (min-width: 360px) {
    .jra-details-card {
        .approve-reject-modal .reject-btn {
            padding: 5px 40px;
            margin-left: 14px;
        }

        .approve-reject-modal .approve-btn {
            padding: 5px 42px;
        }
    }
}

@media (min-width: 412px) {
    .jra-details-card {
        .approve-reject-modal .reject-btn {
            padding: 5px 50px;
            margin-left: 14px;
        }

        .approve-reject-modal .approve-btn {
            padding: 5px 48px;
        }
    }
}

@media (min-width: 500px) and (max-width: 600px) {
    .jra-details-card {
        .approve-reject-modal .reject-btn {
            padding: 5px 80px;
            margin-left: 14px;
        }

        .approve-reject-modal .approve-btn {
            padding: 5px 85px;
        }
    }
}