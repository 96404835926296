@import "src/index.scss";

.header-3 {
  padding: 10px;
  .row-1 {
    .dot {
      margin: $default-margin;
      height: 10px;
      width: 10px;
      border-radius: 10px;
      background-color: gray;
    }

    .label-1 {
      margin: $default-margin;
    }

    .label-2 {
      margin: $default-margin;
    }
  }

  .row-2 {
    .label-3 {
      margin: $default-margin;
    }
  }


}
