@import "src/index.scss";

.watershed-sheet-details {
    margin-top: 20px;

    .no-watershed-sheet-details {
        color: #9b9b9b;
        display: flex;
        justify-content: center;
        margin: $default-margin();
        font-weight: bold;
    }

    .main-row {
        border: 1px solid rgba(224, 224, 224, 1);

        .table-text {
            color: #4a4a4a;
            font-weight: 600;
        }

        .link {
            color: #0014DC;
            cursor: pointer;
            text-decoration: none;
        }
    }

    .spacing {
        height: 12px;
    }

}