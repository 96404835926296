@import "src/index.scss";

.key-value {
  margin: $default-margin;
  display: flex;
  flex-direction: row;
  align-items: center;
  .key {
    font-size: 14px;
    font-weight: normal;
    color: #9b9b9b;
    margin: 0 5px 0 0;
  }
  .seperator {
    font-size: 14px;
    font-weight: normal;
    color: #9b9b9b;
    margin: 0 5px 0 0;
  }
  .value {
    font-size: 14px;
    font-weight: bold;
    color: #4a4a4a;
    margin: 0;
  }
}
