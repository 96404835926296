@import "src/index.scss";

.dlp-card {
  .row-1 {
    .dot {
      background-color: $dlp;
    }

    .label-1 {
      font-size: 14px;
      color: $dlp;
    }

    .chip-1 {
      .label {
        color: white;
      }
    }
  }

  .row-2 {
    .label-2 {
      font-size: 18px;
      color: #4a4a4a;
    }
  }

  .row-4 {
    .label-3 {
      font-size: 14px;
      color: #83a7b2;
    }

    .col-1 {

      .key-value-2 {
        .key {
          font-size: 14px;
          font-weight: 700;
          color: #4a4a4a;
        }

        .value {
          font-size: 14px;
          font-weight: normal;
          color: #4a4a4a;
        }
      }
    }
  }
}

.chip-1-high {
  .row-1 .chip-1 {
    background-color: #EB5E5E;
  }
}

.chip-1-medium {
  .row-1 .chip-1 {
    background-color: #EA910D;
  }
}

.chip-1-low {
  .row-1 .chip-1 {
    background-color: #3CBE46;
  }
}