.commentCard {
  padding: 20px;

  .cardContainer {
    box-shadow: none;
    border: solid;
    border-color: lightgrey;
    border-width: 1px;
    border-radius: 7px;
    padding: 15px;
    margin-top: 10px;

    .parentContainer {
      display: flex;
      flex-direction: column;
      justify-content: center;

      .commentContainer {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        margin: 5px 10px 5px 53px;

        .comment {
          color: #4a4a4a;
          font-size: 14px;
          word-break: break-word;
          align-self: flex-start;
          text-align: start;
        }

        .commentTitle {
          color: #4a4a4a;
          font-size: 14px;
          font-weight: 700;
          word-break: break-word;
          align-self: flex-start;
          text-align: start;
        }
      }
    }
  }
}

.noComments {
  color: #9b9b9b;
  display: flex;
  justify-content: center;
  padding-top: 5px;
  font-weight: bold;
}