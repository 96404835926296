@import "src/index.scss";

.pr-approvers-tab {
    margin-top: 20px;

    .no-approvers {
        color: #9b9b9b;
        display: flex;
        justify-content: center;
        margin: $default-margin();
        font-weight: bold;
    }

    .main-row {
        border: 1px solid rgba(224, 224, 224, 1);
    }

    .spacing {
        height: 12px;
    }

    .cell-0 {
        font-weight: 600;
    }

    .cell-1 {
        font-weight: 600;
    }

    .cell-3 {
        font-weight: 100;
    }

    .approved {
        color: #3CBE46
    }

    .not-approved {
        color: #9b9b9b;
        
    }
}