@import "src/index.scss";

.jra-header {
  background-color: white;

  .row-1 {
    .dot {
      background-color: $jra;
    }

    .label-1 {
      font-size: 14px;
      color: $jra;
    }

    .label-2 {
      font-size: 14px;
      color: #83a7b2;
    }
  }

  .row-2 {
    .label-3 {
      font-size: 18px;
      color: #4a4a4a;
    }
  }

  .row-3 {
    .col-1 {
      .chip-1 {
        .label {
          color: rgb(255 255 255);
          text-align: center;
        }
      }

      .key-value-1 {
        .key {
          font-size: 14px;
          font-weight: 700;
          color: #4a4a4a;
        }

        .value {
          font-size: 14px;
          font-weight: normal;
          color: #4a4a4a;
        }
      }
    }
  }
}

.chip-1-high {
  .row-3 .col-1>.chip-1 {
    background-color: rgb(253, 84, 106);
  }
}

.chip-1-medium {
  .row-3 .col-1>.chip-1 {
    background-color: #EA910D;
  }
}

.chip-1-low {
  .row-3 .col-1>.chip-1 {
    background-color: #3CBE46;
  }
}