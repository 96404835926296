@import "src/index.scss";

.eQuote-header {
    background-color: white;

    .row-1 {
        .dot {
            background-color: $equote;
        }

        .label-1 {

            color: $equote;
            font-size: 14px;
        }

        .label-2 {
            font-size: 14px;
            color: #83a7b2;
        }
    }

    .row-2 {
        .label-3 {
            font-size: 18px;
            color: #4a4a4a;
        }
    }

    .row-3 {
        .label-4 {
            font-size: 20px;
            font-weight: bold;
        }

        .label-5 {
            font-size: 14px;
            color: #9b9b9b;
        }
    }
}