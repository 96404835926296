@import "src/index.scss";

.card-7 {
    padding: 10px;

    .row-1 {
        .dot {
            margin: $default-margin;
            height: 10px;
            width: 10px;
            border-radius: 10px;
        }

        .label-1 {
            margin: $default-margin;
            font-size: 14px;
        }

    }

    .row-2 {
        .label-2 {
            margin: $default-margin;
        }
    }

    .row-3 {
        .key-value {
            width: fit-content;
        }
    }

    .row-4 {
        flex-wrap: nowrap;

        .col-1 {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: flex-end;


            .label-3 {
                margin: $default-margin;
            }
        }
    }
}