.attachment-cell {
  box-sizing: border-box;
  border: 0.5px solid #979797;
  border-radius: 8px;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 10px;
  margin: 0 15px 15px 0;
  cursor: pointer;
  max-height: 100px;

  .attachment-image {
    width: auto;
    height: 35px;
    padding-bottom: 5px;
  }

  .attachment-filename {
    color: #0014DC;
    font-size: 14px;
    font-weight: 600;
    letter-spacing: 0;
    max-width: 110px;
    width: 110px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    word-wrap: break-word;
    margin: 10px;
    text-align: center;
  }
}

.attachment-cell-downloadable {
  cursor: pointer;
}
