@import "src/index.scss";

.equote-details {
  .grey-bg-color {
    background-color: rgba(202, 203, 239, .33);
  }

  .top-margin-10 {
    margin-top: 10px;
  }

  .top-margin-5 {
    margin-top: 5px;
  }

  .header-details-label {
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    color: #7c7c7c;
  }

  .header-details-subheading-label {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    text-transform: capitalize;
    color: #9b9b9b;

    .header-details-subheading-label-value {
      font-weight: 600;
      color: #4A4A4A;
    }
  }

  .header-details-value {
    font-weight: 700;
    font-size: 18px;
    line-height: 21px;
    text-align: right;
    color: #454969;
  }

  .header-details-currency {
    margin-left: 5px;
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    color: #9b9b9b;
  }

  .no-equote-details {
    color: #9b9b9b;
    display: flex;
    justify-content: center;
    margin: $default-margin();
    font-weight: bold;
  }

  .currency {
    margin-left: 8px;
    font-size: 12px;
    font-weight: 200;
    color: #9b9b9b;
  }

  .row-border {
    border: 1px solid #e0e0e0;
  }
}