@import "src/index.scss";

.app-splash-screen {
  position: absolute;
  top: 0;
  left: 0;
  // Keep z-index grater than that of dialogs (1300)
  z-index: 1301;
  background: #0014dc;
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .logo-image {
    height: 91px;
    width: auto;
    margin: 20px 20 10px 20px;
  }
  .row-1 {
    font-size: 42px;
    font-weight: bold;
    display: flex;
    flex-direction: row;
    align-items: center;
    // margin: 10px 20px 20px 20px;

    .AB-logo {
      margin-top: 30px;
      height: 54px;
      margin-right: 10px;
    }

    .approve {
      color: white;
      font-weight: 400;
    }
    .buddy {
      color: #00d2dc;
      font-weight: 400;
    }
  }

  .row-2 {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;

    .loading-message {
      font-size: 14px;
      color: #97e3f4;
      margin: 25px 20px 10px 20px;
    }

    .message-container {
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;

      .message-1 {
        .title {
          color: white;
        }
        .sub-title {
          color: whitesmoke;
        }
      }
    }

    .linear-progress-container {
      display: flex;
      flex-direction: row;
      align-items: center;
      margin: 10px 20px 20px 20px;

      .linear-progress {
        height: 7px;
        width: 20vw;
        min-width: 250px;
        border-radius: 10px;

        .MuiLinearProgress-dashedColorPrimary {
          margin-top: 1px;
          background-size: 10px 10px;
          background-image: radial-gradient(
            #61d1fd 0%,
            #61d1fd 16%,
            transparent 42%
          );
        }
      }
      .barColor1 {
        background-color: transparent;
      }
      .barColor2 {
        background-color: #61d1fd;
      }
      .percentage {
        font-size: 18px;
        color: whitesmoke;
        margin-left: 10px;
        line-height: 18px;
        width: 25px;
      }
    }
  }
}

@media only screen and (max-width: $tablet-mini) {
  .app-splash-screen {
    .logo-image {
      height: 60px;
      margin: 10px 10 5px 10px;
    }
    .row-1 {
      font-size: 25px;
      margin: 5px 10px 10px 10px;

      .AB-logo {
        margin-top: 30px;
        height: 35px;
        margin-right: 5px;
      }
    }
    .row-2 {
      align-items: center;
      .loading-message {
        font-size: 10px;
        margin: 25px 10px 5px 10px;
      }

      .linear-progress-container {
        margin: 5px 10px 10px 10px;

        .linear-progress {
          height: 7px;
          width: 40vw;
        }
        .percentage {
          font-size: 14px;
          line-height: 10px;
        }
      }
    }
  }
}
