.poReference {
  .poValue {
    color: #000;
  }
  .copyBtn {
    margin-left: 5px;
    cursor: pointer;
  }
  .copiedText {
    margin-left: 10px;
    color: #3cbe46;
  }
}
