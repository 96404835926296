.dlpverification-container {
    min-width: 600px;
    .MuiAccordionSummary-content {
        margin: 0px;
    }

    .main-container {
        .title-container {
            margin-top: 0px;
            color: #4a4a4a;
            border: 1px solid #e0e0e0;
            background-color: rgba(202, 203, 239, 0.33);

            .dlp-section-title {
                align-items: center;
                display: flex;
                font-size: 14px;
                margin: 15px 0;
                padding-left: 10px;
                color: #7c7c7c;
                padding-right: 5px;

                .MuiChip-root {
                    height: 27px;
                }
            }

            .chip-1-high {
                color: white;
                background-color: #EB5E5E;
            }

            .chip-1-medium {
                color: white;
                background-color: #EA910D;
            }

            .chip-1-low {
                color: white;
                background-color: #3CBE46;
            }
        }
    }

    .subtitle-container {
        border: 1px solid #e0e0e0;
        margin-bottom: 20px;

        .MuiAccordionSummary-content.Mui-expanded {
            margin: 0px;
        }
    }

    .verification-count {
        float: right;
        font-size: medium;
    }

    .key-value-list .key-value .key {
        width: 350px;
        min-width: 300px;
        text-align: start;
        display: flex;
        flex-wrap: wrap;
        font-size: 14px;
    }

    .text-red {
        color: red;
    }

    .accordian-container {
        border-bottom: 1px solid #e0e0e0;
    }
}