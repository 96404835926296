@import "src/index.scss";

.list-details-view {
  padding: $default-padding;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  overflow: hidden;
  height: calc(100vh - #{$app-header-height});

  $total-width-available: calc(100% - #{$default-padding}* 2 - #{$default-margin}* 4);

  .list-view {
    margin: $default-margin;
    width: calc(#{$total-width-available} * 0.35);
    height: calc(100% - #{$default-margin}* 4);
    overflow: hidden;
  }

  .details-view {
    margin: $default-margin;
    width: calc((#{$total-width-available}) * 0.65);
    height: calc(100% - #{$default-margin}* 4);
    border-radius: 15px;
    overflow: hidden;
  }

  .details-card-empty-container {
    display: flex;
    flex-direction: column;
    background-color: white;
    height: 100%;
    width: 100%;

    .message-container {
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;

      .message-1 {
        .sub-title {
          color: #000;
          font-size: 14px;
        }
      }
    }

    .refreshcontainer {
      display: flex;
      justify-content: center;
      margin-top: 15px;

      .buttonRefresh {
        color: #FFFFFF;
        border: 1px solid #ABCEF8;
        border-radius: 10px;
        background-color: #0014DC;
        text-transform: none;
        padding: 5px 35px;
        font-size: 17px;
      }

    }
  }
}

.mobile-details-view {
  flex-direction: column;
  width: 100vw;
  height: 100vh;

  $header-height: 50px;

  .header {
    padding: $default-padding;
    background-color: rgb(23, 52, 150);
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    color: white;
    height: $header-height;

    .back-icon {
      margin: $default-margin;
      width: 30px;
      height: 30px;
      cursor: pointer;
    }

    .title {
      margin: $default-margin;
      width: fit-content;
    }
  }

  .content-container {
    height: calc(100vh - #{$header-height});
  }
}

@media only screen and (max-width: $tablet) {
  $total-width-available: calc(100% - #{$default-padding}* 2 - #{$default-margin}* 4);

  .list-details-view {
    .list-view {
      width: calc(#{$total-width-available} * 0.40);
    }

    .details-view {
      width: calc(#{$total-width-available} * 0.60);
    }
  }
}


@media only screen and (max-width: $tablet-mini) {
  .list-details-view {
    $total-width-available: calc(100% - #{$default-padding}* 2);
    height: calc(100vh - #{$app-header-height} - #{$ticker-height});

    .list-view {
      width: $total-width-available;
    }
  }
}

