@import "src/index.scss";

.pr-split-items-accordian {
  .pr-split-item-details {
    width: 100%;
  }
  .MuiAccordionDetails-root {
    padding: 0px;
  }

  .header1 {
    display: flex;
    .split-item-header-content {
      display: flex;
      flex-direction: row;
    }
    
  }
}
