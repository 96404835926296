.split-items-row-text-1 {
  font-size: 12px;
  font-weight: bold;
  margin: 0;
}

.pr-split-items-row-1:last-child>td {
  border-bottom: none;
}

.pr-items-spacing {
  height: 12px;
}

.splitAmount {
  font-size: 14px;
  font-weight: bold;
}

.splitCurrency {
  font-size: 12px;
  font-weight: bold;
  color: #9b9b9b;
}