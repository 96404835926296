@import "src/index.scss";

.pr-items-tab {
  margin-top: 20px;
  overflow: scroll;
  
  .no-items {
    color: #9b9b9b;
    display: flex;
    justify-content: center;
    margin: $default-margin();
    font-weight: bold;
  }

}
