@import "src/index.scss";

.eclaims-attachment-modal {
  overflow: auto;

  .MuiDialog-paperWidthMd {
    width: 90%;
    max-width: none !important;
  }

  .attachment-header {
    padding: 20px 15px 0px 20px;
    display: flex;
    align-items: center;
    .attachment-title {
      color: #030303;
      margin: $default-margin;
      word-break: break-all;
      font-size: 24px;
      font-weight: bold;
      margin-right: 20px;
      white-space: pre-line;
      overflow-wrap: break-word;
      line-height: 24px;
    }
    .download-btn {
      min-width: 100px;
      height: fit-content;
      @include default-button(white, #0014dc);
    }
    .close-btn {
      //NOSONAR
    }
  }
  .divider-1 {
    width: 100%;
    margin-top: 20px;
  }

  .attachment-content {
    overflow-y: auto; 
    overflow-x: auto; 
    background-color: white;
    width: 100%;
    height: 100vh;
    padding: 0; 

    display: flex;
    // justify-content: center;
    align-self: center;

    .doc-viewer-container {
      padding: 0px !important;
      justify-content: none !important;
      .jGqOvK {
        flex-direction: column;
        overflow: hidden;
        background: rgb(238, 238, 238);
        overflow-y: auto;
        overflow-x: auto;
        background-color: white;
        width: 90vw !important;
        padding-left: 40px;
        padding-right: 40px;
        display: flex;
        justify-content: center;
        align-self: center;
        .dTuydp {
          display: none !important;
        }
        .hysiap {
          .egtPel {
            .gwDHUF {
              width: 100%;
              height: 38vw !important;
              border: 0px;
              padding-bottom: 0px;
            }
          }
        }
      }
    }
    .pdf-container{
        width: 100%;
        height: 100%; 
        .pdf-iframe {
            width: 100%; 
            height: auto; 
            max-width: 100vw;
            max-height: 100vh; 
          }

    }
    
    .img-frame {
      width: 100%;
      max-width: 75vw;
      height: auto;
      max-height: 75vh;
      object-fit: contain;
      display: block;
      margin: 0 auto;
    }
    .circular-progress {
      position: absolute;
      top: 45%;
      left: 45%;
      width: 100%;
      height: 100%;
    }
    .no-support {
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      top: 50%;
      left: 50%;
      .file-image {
        position: absolute;
        top: -30%;
        left: 35%;
      }
      .not-supported-text {
        position: absolute;

        //NOSONAR
        .not-supported {
          display: flex;
          align-items: center;
          justify-content: center;
          padding-top: 30px;
          flex-direction: column;
          min-width: 200px;
        }
      }
    }
  }
}

.file-viewer-container {
  position: relative;
  height: 100%; /* adjust these values as needed */
  width: 100%; /* adjust these values as needed */
  overflow: scroll; /* if you want to enable scrolling */
  .pg-viewer-wrapper {
    .pg-viewer {
      .spreadsheet-viewer {
        .react-grid-Container {
          .react-grid-Main {
            .react-grid-Grid {
              .react-grid-Header {
                .react-grid-HeaderRow {
                  pointer-events: none;
                }
              }
            }
          }
        }
      }
    }
  }
}

.approve-buddy-2 .attachment-content::-webkit-scrollbar {
  width: 12px !important; // Adjust width for vertical scrollbar
}

.approve-buddy-2 .attachment-content::-webkit-scrollbar-thumb {
  background-color: darkgrey !important; // Color of the scrollbar, change as required
  border-radius: 12px !important; // Optional: to make the scrollbar rounded
}

.approve-buddy-2 .attachment-content::-webkit-scrollbar-track {
  background-color: lightgrey !important; // Color of the scrollbar track, change as required
}

@media only screen and (max-width: $mobile) {
  .not-supported-text {
    position: absolute;
    top: 46% !important;
    left: 23% !important;
  }
  .no-support {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 50%;
    left: 20% !important;
    .file-image {
      position: absolute;
      top: -30%;
      left: 35%;
    }
    .not-supported-text {
      position: absolute;

      //NOSONAR
      .not-supported {
        display: flex;
        align-items: center;
        justify-content: center;
        padding-top: 30px;
        flex-direction: column;
        min-width: 200px;
      }
    }
  }
}
