@import "src/index.scss";

.pretrip-header {
  background-color: white;

  .row-1 {
    .dot {
      background-color: $pretrip;
    }

    .label-1 {
      color: $pretrip;
      font-size: 14px;
    }

    .label-2 {
      font-size: 14px;
      color: #83a7b2;
    }
  }

  .row-2 {
    .label-3 {
      font-size: 18px;
      color: #4a4a4a;
    }
  }

  .row-3 {
    .label-4 {
      font-size: 18px;
      font-weight: bold;
    }

    .label-5 {
      font-size: 14px;
      color: #9b9b9b;
    }
  }
}

.is-compliant-green {
  .row-3 .label-4 {
    color: #3CBE46;
  }
}

.is-compliant-red {
  .row-3 .label-4 {
    color: #EB5E5E;
  }
}