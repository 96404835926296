@import "src/index.scss";

.new-system-modal {
  $new-system-modal-width: 30vw;

  .new-system-content {
    width: $new-system-modal-width !important;
    max-width: 600px;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: fit-content;
    background-color: white;

    .title {
      width: 100%;
      background: linear-gradient(90deg, rgb(99, 162, 252), rgb(96, 212, 253));
      padding: 20px;
      color: white;
      font-size: 18px;
      text-align: center;
    }

    .bg-container {
      width: #{$new-system-modal-width};
      height: calc(
        #{$new-system-modal-width} * 0.5
      ); //to fit aspect ratio og bg img
      position: relative;

      .background-img {
        position: absolute;
        width: #{$new-system-modal-width};
        height: calc(#{$new-system-modal-width} * 0.5);
        top: 0;
        left: 0;
      }

      .approval-operation-container {
        position: absolute;
        width: #{$new-system-modal-width};
        height: calc(#{$new-system-modal-width} * 0.5);
        top: 0;
        left: 0;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        .approval-operation-img {
          width: calc(#{$new-system-modal-width} * 0.1);
          height: calc(#{$new-system-modal-width} * 0.1);
        }

        .approval-operation-description {
          font-size: calc(14px + (#{$new-system-modal-width} * 0.01));
          color: white;
          margin-bottom: calc(#{$new-system-modal-width} * 0.12);
        }
      }
    }

    .message {
      color: white;
      background-color: #051464;
      font-size: 14px;
      padding: 20px;
      text-align: center;
    }
  }

  .action-container {
    width: 100%;
    background-color: #051464;

    .action-btn {
      margin: $default-margin;
    }

    .cancel-btn {
      @include default-button(white, #0014dc);
    }

    .add-in-btn {
      @include default-button(white, $primary);
    }
  }
}

@media only screen and (max-width: $tablet-mini) {
  .new-system-modal {
    $new-system-modal-width-md: 80vw;

    .new-system-content {
      width: $new-system-modal-width-md !important;

      .bg-container {
        width: #{$new-system-modal-width-md};
        height: calc(#{$new-system-modal-width-md} * 0.5);

        .background-img {
          width: #{$new-system-modal-width-md};
          height: calc(#{$new-system-modal-width-md} * 0.5);
        }

        .approval-operation-container {
          width: #{$new-system-modal-width-md};
          height: calc(#{$new-system-modal-width-md} * 0.5);

          .approval-operation-img {
            width: calc(#{$new-system-modal-width-md} * 0.1);
            height: calc(#{$new-system-modal-width-md} * 0.1);
          }

          .approval-operation-description {
            font-size: calc(11px + (#{$new-system-modal-width-md} * 0.01));
            margin-bottom: calc(#{$new-system-modal-width-md} * 0.12);
          }
        }
      }
    }
  }
}
