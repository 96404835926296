body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}



.approve-buddy-2 ::-webkit-scrollbar {
  display: none;
}

$primary : #106ff9;

.spacer{
  display: flex;
  flex-grow: 1;
}

$default-margin : 5px;

$default-padding : 5px;

@mixin button-styles($bg-color){
  &:hover{
    background-color: darken($bg-color, 10%);
  }
  &:active{
    background-color: darken($bg-color, 20%);
  }
  &:disabled{
    cursor: not-allowed;
    background-color: lightgray;
  }
}

@mixin default-button($text-color, $bg-color){
  color: $text-color;
  padding: 5px 25px;
  font-weight: bold;
  cursor: pointer;
  background-color: $bg-color;
  @include button-styles($bg-color);
}

// global vars
$app-header-height : 65px;
$app-sidebar-width : 80px;
$ticker-height : 67px;

//break-points
$mobile-mini : 280px;
$mobile : 480px; 
$tablet-mini : 768px;
$tablet : 820px;
$laptop : 1024px;
$desktop : 1200px;

//system colors

$ejourney : #309840;
$timesheet : #D53788;
$jra : #24c2a8;
$pretrip : #ee4466;
$pr : #0997c8;
$cart : #e65f24;
$po : #3875c3;
$dlp : #e68c01;
$equote : #8152ca;
$shopping-cart : #cca305;
$quest : #Ae3Bd3;
$task-sheet : #7a971c;
$eclaims : #00A0C2;

