@import "src/index.scss";

.detail-skeleton-1{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    height: 100%;
    width: 100%;
    padding: 10px 20px;
    
    .s-1{
        width : calc(100% - 10px);
        height : 30px;
    }

    .s-2{
        width : 40%;
        height : 20px;
    }

    .s-3{
        margin : $default-margin;
        margin-top: 20px;
        display: flex;
        flex-grow: 1;
        width: calc(100% - 10px); 
    }

    .circle-1{
        width: 42px;
        height: 42px;
        margin : $default-margin;
    }

    .row-1{
        margin-top: 10px;
    }

   
    
    .row-3{
        display: flex;
        flex-direction: row;
        width: 100%;
        margin-top: 10px;
        align-items: center;

        .row-3-column-1{
            display: flex;
            flex-direction: column;
            flex-grow: 1;
        }
    }

}