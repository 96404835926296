@import "src/index.scss";

.card-container {
  display: flex;
  background-color: white;
  border-radius: 15px;
  width: fit-content;
  cursor: pointer;

  &:hover {
    background-color: lighten($primary, 45%);
  }

  .content-container {
    width: 100%;
    border-radius: 15px 0 0 15px;
    overflow: hidden;
  }

  .not-selected-indicator{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 15px;
    background-color: transparent;
    border-radius: 0 15px 15px 0;
  }

  .selected-indicator {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 15px;
    background-color: #00D2DC;
    border-radius: 0 15px 15px 0;

    .selected-indicator-icon {
      color: white;
    }
  }
}
