.srm-attachments {
  .no-srm-items {
    color: #9b9b9b;
    display: flex;
    justify-content: center;
    margin: 5px;
    margin-bottom: 3%;
    font-weight: 600;
    padding-top: 30px;
  }
}
