@import "src/index.scss";


.optimus-card {
  .row-1 {
    .dot {
      background-color: #136FD5;
    }

    .label-1 {
      color: #136FD5;
      font-size: 14px;
    }

    .key-value-1 {
      .key {
        font-size: 18px;
        font-weight: 700;
        color: #4a4a4a;
      }

      .value {
        font-size: 12px;
        font-weight: normal;
        color: #9b9b9b;
      }
    }
  }


  .row-2 {
    .label-2 {
      font-size: 18px;
      color: #4a4a4a;
    }
  }

  .row-4 {
    .col-1 {
      .chip-1 {
        background-color: #136FD5;

        .label {
          color: white;
        }
      }

      .label-3 {
        font-size: 14px;
        color: #83a7b2;
      }
    }
  }

  @media (min-width: 375px) and (max-width: 400px) {
    .user-details {
      max-width: 62%;
    }
  }

  @media (min-width: 1025px) {
    .user-details {
      max-width: 55%;
    }
  }

  @media (min-width: 540px) and (max-width: 720px) {
    .user-details {
      max-width: 58%;
    }
  }

  @media (min-width: 412px) and (max-width: 539px) {
    .user-details {
      max-width: 65%;
    }
  }
}

.chip1-hidden {
  .row-4 .chip-1 {
    display: none;
  }
}


@media only screen and (max-width: 375px) {
  .po-card {
    .row-1 {
      .dot {
        background-color: lightskyblue;
      }

      .label-1 {
        color: lightskyblue;
        font-size: 14px;
      }

      .key-value-1 {
        .key {
          font-size: 14px;
          font-weight: 700;
          color: #4a4a4a;
        }

        .value {
          font-size: 13px;
          font-weight: normal;
          color: #9b9b9b;
        }
      }
    }

    .row-4 {
      .col-1 {
        .chip-1 {
          background-color: #136FD5;

          .label {
            color: white;
          }
        }

        .label-3 {
          font-size: 14px;
          color: #83a7b2;
          padding-top: 22px;
        }
      }
    }
  }
}