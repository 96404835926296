.cell-note {
  position: relative;
}

.cell-note:after {
  content: "";
  position: absolute;
  display: block;
  top: 0;
  right: 0;
  border-left: 7px solid transparent;
  border-top: 7px solid red;
}

.container-fluid {
  .table-main {
    margin-top: 20px;
  }
  .MuiTabs-root {
    display: flex;
    flex-wrap: wrap;

    .MuiTab-root {
      font-size: 12px;
      overflow: hidden;
      text-overflow: ellipsis;
      max-width: 100%;
      flex: 0 0 150px; /* Adjust this value as per your requirement */
    }
  }
  .MuiTabs-flexContainer {
    max-width: 185px;
  }
  .MuiAppBar-colorDefault {
    background-color: white;
  }
}

.excel-table {
  tr {
    border: 1px solid grey;
  }
  td {
    border: 1px solid grey;
  }
}

/* Set the AntTabs to be a flex container and allow items to wrap */
.AntTabs-root {
  display: flex;
  flex-wrap: wrap;
}

/* Set a fixed width for each tab */
.AntTabs-root .MuiTab-root {
  flex: 0 0 150px; /* This sets the tab width to 150px. Adjust as needed. */
}
