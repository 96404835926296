@import "src/index.scss";

.hse-detail-modal {
    .title-text1-container {
        padding: 20px 15px 0px 20px;
        display: flex;
        width: 100%;
        flex-direction: row;
        justify-content: flex-start;

        .titleText {
            color: #4a4a4a;
            margin: $default-margin;

            font-size: 24px;
            font-weight: bold;
            letter-spacing: 0;
            line-height: 38px;
        }

        .closeButton {
            justify-content: flex-end;
        }
    }

    .hse-information-content {
        flex-direction: column;
        justify-content: center;
        align-items: center;
        height: fit-content;
        background-color: white;
        width: auto;
        max-width: 100%;
        min-width: 50vh;

    }

    .MuiDialog-paperWidthSm {
        max-width: 60%;
    }

}

@media only screen and (max-width:$tablet-mini) {
    .hse-information-modal {
        .hse-information-content {
            width: unset;
        }
    }
}