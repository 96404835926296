@import "src/index.scss";

.time-sheet-details {
  margin-top: 20px;

  .headerLabel {
    color: #4a4a4a;
    font-size: 14px;
    font-weight: 300;
    width: 30%;
  }

  .padding-18 {
    padding: 18px;
  }

  .header-label {
    color: #6b6e93;
    font-size: 22px;
    font-weight: 700;
  }

  .grey-bg-color {
    background-color: rgba(202, 203, 239, 0.33);
  }

  .top-margin-5 {
    margin-top: 5px;
  }

  .no-time-sheet-details {
    color: #9b9b9b;
    display: flex;
    justify-content: center;
    margin: $default-margin();
    font-weight: bold;
  }

  .row {
    border: 1px solid rgba(224, 224, 224, 1);
  }

  .MuiTableCell-root {
    padding: 2px;
  }

  .day {
    font-weight: 600;
  }

  .type {
    font-weight: 600;
  }

  .date {
    font-weight: 600;
  }
}
