.jra-design {
    border-width: 0.5px;
    border-style: solid;
    border-color: #CCCDD8;
    margin-top: 12px;

    .questions-header {
        background-color: #f9f9f9;
        padding: 10px 20px;
        justify-content: space-between;
        color: #585a73;
        font-size: 18px;
        font-weight: 500;

        .header-col2 {
            color: #4a4a4a;
            font-size: 14px;
        }

        .ans-btn {
            text-align: center;
        }
    }

    .question-body {
        padding: 10px 20px 10px 30px;
        justify-content: space-between;
    }

    .MuiAccordionDetails-root {
        padding: 0px;
    }

    .accrodian-header {
        font-size: 18px;
        font-weight: 700;
        color: #6b6e93;
    }

    .non-compliant-heading {
        color: #EB5E5E;
        font-size: 16px;
        font-weight: 700;
        margin-left: 10px;

    }

    .heading-item-count {
        border-radius: 8px;
        background-color: #c0c3d8;
        margin-left: 10px;
        padding: 4px;

        .count-value {
            color: #fff;
            font-size: 20px;
            font-weight: 500;
            padding: 0 13px;
        }



    }

    .body-wrap {
        border-bottom: 1px solid rgba(151, 151, 151, 0.23);
        margin-top: 10px;
    }

    .question-label {
        color: #454969;
        font-size: 16px;
    }

    .comment-label {
        color: #7e7e7e;
        font-size: 14px;
        font-style: italic;
    }

    .comment-value {
        color: #4a4a4a;
        font-size: 14px;
    }

    .answer-block {
        margin-top: 1%;
        padding-right: 20px;

        .answer {
            margin-left: auto;
            margin-right: auto;
            display: block;
            text-align: center;

            .answer-value {
                font-weight: 700;
                letter-spacing: 0;
                line-height: 21px;
                color: #000;
                border-radius: 8px;
                background-color: rgb(255 227 230);
                padding: 10px;
            }
        }
    }

    .additional-risk-block {
        margin-top: 10px;

        .risk-value {
            color: #fff;
            font-size: 16px;
            font-weight: 700;
            letter-spacing: 0;
            line-height: 21px;
            padding: 0 13px;
        }

        .risk-btn {

            .btn {
                border-radius: 8px;
                padding: 6px;
            }

            .medium {
                background-color: #EA910D;
            }

            .high {
                background-color: #EB5E5E;
            }

            .low {
                background-color: #3CBE46;
            }
        }



    }


}