.cardParent {
    border-bottom: 1px solid #e0e0e0;
    border-right: 1px solid #e0e0e0;
    border-left: 1px solid #e0e0e0;
    margin-bottom: 20px;
    margin-top: 5px;

    .panelheadertext {
        font-size: 16px;
    }

    .answerTitleContainer {
        .MuiTableCell-root {
            padding: 1px;
        }
    }

    .cardContainer {
        .questionCategory {
            background-Color: #e9ebf5;
            padding: 16px;
            padding-bottom: 15px;
            color: black;
            border: 1px solid #e0e0e0;
        }

        .firstRowContainer {
            box-sizing: border-box;
            border-bottom-style: none;
            background-color: #FFFFFF;

            .borderbox {
                border: 0.5px solid lightgrey;
            }

            .destinationContainer {
                display: flex;
                flex-direction: column;
                justify-content: flex-start;
                box-sizing: border-box;
                border-right-style: none;
                border-top-style: none;
                border-bottom-style: none;
                padding: 10px;


                .titleText {
                    color: #9B9B9B;
                    font-size: 14px;
                    letter-spacing: 0;
                    line-height: 16px;
                    padding-bottom: 5px;
                }

                .bodyText {
                    color: #000000;
                    font-size: 14px;
                    font-weight: bold;
                    letter-spacing: 0;
                    line-height: 19px;
                    word-break: break-word;
                }

                .durationContainer {

                    display: flex;

                    .durationNum {
                        color: #000000;
                        font-size: 14px;
                        font-weight: bold;
                        letter-spacing: 0;
                        line-height: 19px;
                        padding-right: 2px;
                    }

                    .durationLabel {
                        color: #9B9B9B;
                        font-size: 14px;
                        letter-spacing: 0;
                        line-height: 16px;
                        padding-right: 2px;
                        padding-top: 2px;
                    }
                }

                .riskContainer {
                    display: flex;
                    flex-direction: row;
                    justify-content: flex-start;
                    margin-top: 10px;

                    .riskLabel {
                        color: #9B9B9B;
                        font-size: 14px;
                        letter-spacing: 0;
                        line-height: 16px;
                    }
                }

            }
        }

        .secondRowContainer {
            box-sizing: border-box;
            border-top: 0.5px solid lightgrey;
            border-bottom-style: none;
            background-color: #FFFFFF;
            padding: 10px;

            .questionTitleContainer {
                display: flex;
                flex-direction: row;
                justify-content: flex-start;

                .questionTitleText {
                    color: #9B9B9B;
                    font-size: 14px;
                    letter-spacing: 0;
                    line-height: 16px;
                    padding-top: 2px;
                }
            }

            .answerTitleText {
                color: #000000;
                font-size: 14px;
                font-weight: bold;
                letter-spacing: 0;
                line-height: 19px;
                padding-left: 10%;
            }


            .tableContainer {
                :global {
                    .MuiTableCell-root {
                        padding: 0 5px 5px 5px;
                    }
                }

                .tabBorder {
                    border: none
                }

                .tableBodyConatiner {

                    .tableRowContainer {

                        .datacol1 {
                            border-bottom: none;
                            color: #000000;
                            font-size: 14px;
                            font-weight: bold;
                            letter-spacing: 0;
                            line-height: 19px;
                        }

                        .datacol3 {
                            border-bottom: none;
                            color: #000000;
                            font-size: 14px;
                            font-weight: bold;
                            letter-spacing: 0;
                            line-height: 19px;
                        }
                    }

                }


            }
        }
    }
}

.noDetails {
    display: flex;
    justify-content: center;
    padding-top: 5%;
    font-weight: bold;
    color: #9b9b9b;
}