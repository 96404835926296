@import "src/index.scss";

.srm-items-tab {
  margin-top: 20px;

  .no-srm-items {
    color: #9b9b9b;
    display: flex;
    justify-content: center;
    margin: 5px;
    font-weight: 600;
    padding-top: 30px;
  }

  .MuiAccordion-rounded:last-child {
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
  }

  .MuiAccordion-rounded:first-child {
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
  }

  .spacing {
    height: 12px;
  }

  .srm-items-row-text-1 {
    font-size: 12px;
    font-weight: bold;
    margin: 0;
  }

  .srm-items-row-key-value-1 {
    .key {
      font-size: 12px;
      font-weight: bold;
      color: #4a4a4a;
    }

    .value {
      font-size: 12px;
      font-weight: bold;
      color: #9b9b9b;
    }
  }

  .detail-row {
    .MuiAccordionDetails-root {
      display: unset;
      padding: 0px;
    }
    .border-div {
   
      .border-bottom {
        border-bottom: 1px solid rgba(224, 224, 224, 1);
        .section-Heading {
          height: 19px;
          margin-left: 1%;
          color: #6b6e93;
          font-size: 16px;
          font-weight: 500;
          line-height: 19px;
        }
      }
    }
    .section-table {
      td {
        padding: 16px 6px 2px 16px;
        color: #000000;
        font-size: 12px;
        font-weight: 400;
        letter-spacing: 0;
        line-height: 16px;
      }

      .custom-table .table-row .table-cell .header-cell-content .header-label {
        font-size: 12px;
        color: #7c7c7c;
        min-width: 50px;
      }

      th {
        padding: 16px 2px 2px 16px;
      }
    }

    .MuiIconButton-label {
      color: #adb0c9;
      padding: 1px;
      background-color: #edeef8;
      border-radius: 10px;
    }

    .header-content {
      height: 19px;
      color: #6d7296;
      font-size: 15px;
      font-weight: 600;
      letter-spacing: 0;
      line-height: 19px;

      .headerCount {
        float: right;
        border-radius: 13px;
      }
    }

    .accordian-container {
      border-bottom: 1px solid rgba(224, 224, 224, 1);
    }

    border: 1px solid rgba(224, 224, 224, 1);

    .accordian-header {
      height: 19px;
      color: #6d7296;
      font-size: 15px;
      font-weight: 600;
      letter-spacing: 0;
      line-height: 19px;
    }

    .MuiTableCell-root {
      padding: 0px;
      border-bottom: none;
    }

    .MuiPaper-elevation1 {
      box-shadow: none;
    }

    .MuiButtonBase-root {
      background-color: #fafafd;
    }
  }

  .srm-item-rows {
    .unit {
      font-weight: 400;
      font-size: 12px;
      line-height: 14px;
      text-align: right;
      color: #9b9b9b;
    }
    .srm-items-row-text-1 {
      font-size: 12px;
      font-weight: bold;
      margin: 0;
    }

    .srm-items-row-key-value-1 {
      .key {
        font-size: 12px;
        font-weight: bold;
        color: #4a4a4a;
      }

      .value {
        font-size: 12px;
        font-weight: bold;
        color: #9b9b9b;
      }
    }

    @mixin srm-items-row() {
      border: 0.5px solid #979797;
      background: #e8e8f8;
    }

    .srm-items-row-1 {
      @include srm-items-row();
    }

    .srm-items-row-2 {
      @include srm-items-row();
      background-color: #f0f0fb;
    }

    .srm-items-row-3 {
      @include srm-items-row();
      background-color: #f0f0fb;
    }

    .srm-items-row-4 {
      @include srm-items-row();

      .key-value-list {
        margin: 0;
      }
    }

    .srm-items-row-5 {
      @include srm-items-row();

      .other-details {
        padding: 0px;
      }

      .key-value-list {
        margin: 12px 0 12px 0;
      }
    }

    .srm-items-row-6 {
      @include srm-items-row();

      .key-value-list {
        margin: 12px 0 12px 0;
      }
    }

    .srm-items-row-7 {
      @include srm-items-row();
      padding: 0px;

      .split-item-details {
        padding: 0px;
      }
    }

    .srm-items-spacing {
      height: 12px;
    }
  }
}
