@import "src/index.scss";

.pretrip-trip-hse-container {
  .main-container {
    .title-container {
      margin-top: 20px;
      color: #4a4a4a;
      border: 1px solid #e0e0e0;
      background-color: rgba(202, 203, 239, 0.33);

      .pretrip-section-title {
        align-items: center;
        display: flex;
        font-size: 14px;
        margin: 15px 0;
        padding-left: 10px;
        color: #7c7c7c;
        padding-right: 5px;
      }
    }
  }

  .certi-Status {
    font-size: 16px;
    margin-top: 0px;
    font-weight: bold;
  }

  .certificate-invalid {
    color: #EB5E5E;
  }

  .certificate-valid {
    color: #3CBE46;
  }

  .certi-Table {
    width: 100%;
    margin-left:-170px;
    .certi-header {
      color: #9b9b9b;
      font-size: 12px;
      letter-spacing: 0;
      line-height: 16px;
    }
    .exp{
      width:100px
    }

    .certi-column {
      text-align: left;
      padding: 8px;
    }

    .certi-name {
      color: #000000;
      font-size: 14px;
      font-weight: 900;
      letter-spacing: 0;
      line-height: 16px;
      border: none;
    }

    .certi-valid {
      font-size: 14px;
      font-weight: bold;
      letter-spacing: 0;
      line-height: 16px;
      border: none;
    }

    .certi-date {
      color: #4a4a4a;
      font-size: 12px;
      font-weight: bold;
      letter-spacing: 0;
      line-height: 16px;
      border: none;
    }
  }

  .subtitle-container {
    border: 1px solid #e0e0e0;

    .key-value-list .key-value .approval-text {
      margin-left: 30px;
    }



    .load-Value {
      color: #0014DC;
      text-decoration: underline;
      cursor: pointer;
    }
  }



  .no-record-quest {
    color: #cbcbcb;
    font-size: 14px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 16px;
    margin-top: -8px;
  }
}


@media screen and (min-width: 830px) {
  .pretrip-trip-hse-container {
    .certi-Table {
      width: 100%;
      margin-left: -10px;
    }
  }
}

@media screen and (width: 540px) {
  .pretrip-trip-hse-container {
    .certificate-valid {
      color: #78d000;
    }
    .certi-Table {
      width: 100%;
      margin-left: -10px;
    }
  }
}

.value-cert{
  margin-left: 30px;
}

.load-Value {
  margin-left: 30px;
  color: #0014DC;
  text-decoration: underline;
  cursor: pointer;
}
