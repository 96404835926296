@import "src/index.scss";

.sub-system-list {
  .sub-system {
    margin: $default-margin;
    border-radius: 14px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    background-color: #5361EC;
    border: 1px solid #5361EC;
    width: fit-content;
    cursor: pointer;

    .key {
      font-size: 14px;
      color: white;
      padding: 0 10px;
    }

    .value {
      margin: 0;
      font-size: 14px;
      color: white;
      background-color: #848EF5;
      border-radius: 14px;
      padding: 5px 10px;
    }
  }

  .sub-system-highlight {
    border: 1px solid white;
  }

  .sub-system-disabled {
    opacity: 0.8;
    background-color: rgba(211, 211, 211, 0.753);
    cursor: default;

    .value {
      background-color: rgba(211, 211, 211, 0.938);
    }
  }

  .sub-system-cancel-icon {
    margin: $default-margin;
    color: white;
    height: 20px;
    width: 20px;
    cursor: pointer;
  }
}