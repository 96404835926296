@import "src/index.scss";

.chip{
    margin: $default-margin;
    border-radius: 20px;
    padding: 5px 15px;
    background-color: lightgrey;
    .label{
        font-size: 12px;
        margin: 0;
    }
}