$tab-width: 110px;
$tab-active-left-margin: 25px;

.poInsight-container {
  margin-top: 2%;
  width: 100%;
  background-color: #f7eccf;
  padding-left: 20px;

  .button {
    display: inline-block;
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;

    .button-style {
      border-radius: 3px;
      background-color: #b67303;
      color: white;
      position: absolute;
      right: 19px;
      border: 1px solid #b67303;
      width: 180px;
      height: 25px;
    }
  }

  .note {
    display: flex;
    align-items: flex-start;
    padding: 10px;

    .note-style {
      background-color: #f7e9ca;
      border: 1px solid #b67303;
      min-width: 495px;
      font-weight: 600;
      padding: 4px;
    }
    
    @media screen and (max-width: 767px) {
      .note-style {
        min-width: auto;
        width: 100%;
        height: auto;
        padding: 8px;
        box-sizing: border-box;
      }
    }
  }

  .power-bi-report{
    iframe {
      width: 100%;
      border-width: 0px !important;
      height: 250px !important;
      zoom: 0.9;
      /* adjust this value to your preference */
      // transform: scale(0.15);
      /* alternative to zoom property */
      border: none;
      min-height: 165px;
      border-color: #f7eccf !important;
      background-color: #f7eccf !important;
      line-break: strict;
    }
  }
}

.poInsight-title {
  padding-left: 30px;
  margin-bottom: 1px;
}



@media only screen and (max-width: 500px) {
  iframe {
    height: 400px !important;
  }
}

.powerBI-report-sec {
  margin-top: 10px;
  position: sticky;
  bottom: 0;

  .title-align img {
    margin-right: 5%;
    max-width: 100%;
    height: auto;
  }

  .header-title {
    display: flex;
    align-items: center;
    font-size: 14px !important;
    width: 100%;
    color: #b67303;
    font-weight: 700;
  }

  .MuiAccordionSummary-root {
    background: rgba(237, 180, 26, 0.4);
  }

  .MuiAccordion-root.Mui-expanded {
    box-shadow: none;
  }

  .accordian-details {
    margin-top: -4px;
  }

  .MuiAccordionSummary-content.Mui-expanded {
    margin: 1px 0;
  }

  .MuiAccordionSummary-root.Mui-expanded {
    min-height: 30px;
  }

  .MuiCollapse-wrapper {
    background-color: #f7eccf;
  }

  .title-align {
    display: flex;
    min-width: 340px;
    align-items: center;
    animation: wobble 1.5s ease-in-out infinite;
    padding-left: 2%;
  }

  .wobble-stop {
    animation-play-state: paused !important;
  }

  @keyframes wobble {
    16.65% {
      -webkit-transform: translateX(8px);
      transform: translateX(8px);
    }

    33.3% {
      -webkit-transform: translateX(-6px);
      transform: translateX(-6px);
    }

    49.95% {
      -webkit-transform: translateX(4px);
      transform: translateX(4px);
    }

    66.6% {
      -webkit-transform: translateX(-2px);
      transform: translateX(-2px);
    }

    83.25% {
      -webkit-transform: translateX(1px);
      transform: translateX(1px);
    }

    100% {
      -webkit-transform: translateX(0);
      transform: translateX(0);
    }
  }

  .errorMessage {
    text-align: center;
  }

  .MuiTab-wrapper {
    text-transform: none;
  }

  .MuiPaper-root {
    .MuiPaper-elevation1 {
      box-shadow: none;
    }
  }

  .MuiTabs-flexContainer {
    background-color: #f7eccf;
  }

  // .PrivateTabIndicator-colorSecondary-3 {
  //   background-color: black !important;
  // }

  .tab {
    &.active {
      width: $tab-width;
      left: $tab-active-left-margin;
    }

    &:not(.active) {
      transform: translateX($tab-active-left-margin);
    }
  }

  .link-icon {
    display: flex;
    margin-right: 7px;
    text-decoration: none;
    padding-top: 1px;
  }

  .button-text {
    font-size: 12px;
  }

  .note-text {
    font-size: 12px;
    color: #b67303;
    font-style: italic;
  }
}
