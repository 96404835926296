@import "src/index.scss";

.card-5 {
  padding: 10px;

  .row-1 {
    flex-wrap: nowrap;
    .dot {
      margin: $default-margin;
      height: 10px;
      width: 10px;
      border-radius: 10px;
      background-color: gray;
    }

    .label-1 {
      margin: $default-margin;
      color: gray;
    }

    .label-2 {
        margin: $default-margin;
    }
  }

  .row-2 {
    .label-3 {
      margin: $default-margin;
    }
  }

  

  .row-4 {
    flex-wrap: nowrap;
    .label-4 {
      margin: $default-margin;
    }
  }
}
