@import "src/index.scss";

.swps-items-tab {
  margin-top: 20px;
  
  .no-items {
    color: #9b9b9b;
    display: flex;
    justify-content: center;
    margin: $default-margin();
    font-weight: bold;
  }

  .main-row{
    border: 1px solid rgba(224, 224, 224, 1);
    .table-cell{
      font-weight: 600;
    }
  }

  .sub-row{
    border-left: 1px solid rgba(224, 224, 224, 1);
    border-right: 1px solid rgba(224, 224, 224, 1);
    border-bottom: 1px solid rgba(224, 224, 224, 1);
  }

  .spacing{
    height: 12px;
  }

  .sub-row-content {
    .key-value-list{
      margin: 0;
    }
  }
}
