@import "src/index.scss";

$menu-item-selected-indicator-width: 5px;
$menu-item-height: 85px;

.app-sidebar {
  display: flex;
  flex-direction: column;
  width: $app-sidebar-width;
  height: calc(100vh - #{$app-header-height});
  overflow: auto;
  background-color: #051464;

  .menu {
    height: 100%;
    overflow: scroll;
    .tempSystemSelection {
      margin-left: auto;
      margin-right: auto;
      display: block;
      .iconStyle {
        font-size: 24px;
        color: #00d2dc;
        font-weight: 600;
      }
    }

    .menu-item {
      display: flex;
      flex-direction: row;
      color: white;
      justify-content: flex-start;
      align-items: center;
      text-decoration: none;
      height: $menu-item-height;

      &:hover {
        background-color: darken(rgb(11, 83, 165), 10%);
      }

      .menu-item-selected-indicator {
        width: 0;
        height: 100%;
        background-image: none;
      }

      .menu-item-content {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        flex-grow: 1;
        padding: 25px 10px 5px 10px;

        .system-icon {
          width: 25px;
          height: 25px;
          margin-top: 0.3rem;
        }

        .title {
          margin: $default-margin;
          font-size: 12px;
          text-align: center;
        }

        .loading {
          color: rgb(40, 170, 198);
          width: 20px !important;
          height: 20px !important;
        }

        .error {
          width: 20px;
          height: 20px;
        }

        .count {
          color: #003366;
          background-color: #00d2dc;
          border-radius: 50px;
          min-width: 24px;
          min-height: 24px;
          padding: 6px;
          text-align: center;
        }
      }
    }

    .menu-item-selected {
      background-color: #031494;

      &:hover {
        background-color: #031494;
      }

      .menu-item-selected-indicator {
        width: $menu-item-selected-indicator-width;
        background-image: linear-gradient(135deg, #66a5ff, #54e8ff);
      }

      .menu-item-content {
        padding: 25px 10px 5px
          calc(10px - #{$menu-item-selected-indicator-width});
      }
    }
  }

  .branding {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .title {
      font-size: 8px;
      color: white;
      margin: $default-margin; //NOSONAR
    }

    .branding-icon {
      width: calc(40px);
      height: auto;
    }

    .bot-icon {
      width: calc(50px);
      height: auto;
      animation: wobble 1.5s ease-in-out infinite;
    }

    .version {
      font-size: 12px;
      color: white;
      margin: $default-margin; //NOSONAR
    }
  }
  .iframe-hidden {
    display: none;
  }

  .chat-popup {
    position: fixed;
    bottom: 129px;
    left: 102px;
    width: auto;
    height: 400px;
    border-radius: 14px;
    border: 0px ;
    
    box-shadow: 0 5px 36px rgba(0,0,0,0.4);
    z-index: 1000;
    display: none;
  }
  @media screen and (max-width: 398px) {
    .chat-popup {
     width:17rem
    }
  }

  .iframe-visible {
    display: block;
  }

  .wobble-stop {
    animation-play-state: paused !important;
  }

  @keyframes wobble {
    16.65% {
      -webkit-transform: translateX(8px);
      transform: translateX(8px);
    }

    33.3% {
      -webkit-transform: translateX(-6px);
      transform: translateX(-6px);
    }

    49.95% {
      -webkit-transform: translateX(4px);
      transform: translateX(4px);
    }

    66.6% {
      -webkit-transform: translateX(-2px);
      transform: translateX(-2px);
    }

    83.25% {
      -webkit-transform: translateX(1px);
      transform: translateX(1px);
    }

    100% {
      -webkit-transform: translateX(0);
      transform: translateX(0);
    }
  }
}
