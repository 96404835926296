.jra-comments {
    border-width: 0.5px;
    border-style: solid;
    border-color: #CCCDD8;
    margin-top: 12px;

    .heading-item-count {
        border-radius: 8px;
        background-color: #c0c3d8;
        margin-left: 10px;
        padding: 4px;

        .count-value {
            color: #fff;
            font-size: 20px;
            font-weight: 500;
            padding: 0 13px;
        }
    }

    .MuiAccordionDetails-root {
        padding: 0px;
    }

    .tableContainer {
        table {
            width: 100%;

            @media (max-width: 770px) {
                width: 130%;
            }
        }
    }

    .requesterCommentsContainer {
        padding: 0px;
    }

    .commentHeader {
        font-size: 18px;
        font-weight: 700;
        color: #6b6e93;
    }

    .headerRow {
        background-color: #f9f9f9;
    }

    .headerTextCell {
        color: #585a73;
        font-size: 18px;
        font-weight: 500;
        border-bottom: none;

        @media (max-width: 770px) {
            font-size: 14px;
        }
    }

    .valueTextCell {
        color: #454969;
        font-size: 16px;
        border-bottom: none;

        @media (max-width: 770px) {
            font-size: 12px;
        }
    }

}