@import "src/index.scss";

.userDetails {
    display: flex;
    margin: $default-margin;

    .image {
        .userInitials {
            border-radius: 50%;
            background: linear-gradient(133.55deg, #66A5FF 0%, #54E8FF 100%);
            justify-content: center;
            width: 42px;
            height: 42px;
            font-size: 16px;
            color: #FFFFFF;
            display: flex;
            align-items: center;
        }

        .userPhoto {
            border-radius: 50%;
            height: 42px;
            width: 42px;
        }
    }

    .nameOrg {
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding-left: 5px;

        .name {
            color: black;
            font-size: 14px;
            font-weight: 500;
            word-break: keep-all;
        }

        .organization {
            color: #9B9B9B;
            font-size: 14px;
            font-weight: 500;
            word-break: keep-all;
        }

        .nameLoader {
            width: 100px;

            @media (max-width:900px) {
                width: 75px;
            }

            @media (max-width:600px) {
                width: 90px;
            }
        }
    }
}