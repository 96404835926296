@import "src/index.scss";

.approve-reject-modal{
    margin: $default-margin;
    width: fit-content;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;

    .action-btn{
        margin: $default-margin;
        width: fit-content;
    }

    .approve-btn{
        @include default-button(white, #3CBE46);
    }
    .reject-btn{
        @include default-button(white, #EB5E5E);
    }
    .forward-btn{
        @include default-button(white, #6E8CC8);
    }
}