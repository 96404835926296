
.po-changelog-text-visible{
    display : flex;
    flex-direction: row-reverse;
    color: red;
    font-weight: bold;
    
}
.po-changelog-text-hidden{
    display: none;
}