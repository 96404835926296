@import "src/index.scss";

.eclaims-card {
  .row-1 {
    .dot {
      background-color: $eclaims;
    }
    .label-1 {
      color: $eclaims;
      font-size: 14px;
    }

    .key-value-1 {
      .key {
        font-size: 18px;
        font-weight: 700;
        color: #4a4a4a;
      }

      .value {
        font-size: 12px;
        font-weight: normal;
        color: #9b9b9b;
      }
    }
  }

  .row-2 {
    .label-2 {
      font-size: 18px;
      color: #4a4a4a;
      font-weight: 600;
    }
  }

  .row-3 {
    .label-3 {
      margin: $default-margin;
      color: #4a4a4a;
      font-weight: 600;
    }
  }

  .row-5 {
  }

  .row-6 {
    .label-4 {
      font-size: 14px;
      color: #83a7b2;
    }
  }
}
