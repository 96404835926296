.accordian-container {
  width: 100%;
  // border: 1px solid lightgrey;
  .accordian {
    box-shadow: none;
    .accordian-summary {
      background-color: #fafafd;
      min-height: 43px;
      .header-content {
        width: 100%;
        color: #9b9b9b;
        font-size: 14px;
      }
    }
    .MuiIconButton-label {
      color: #adb0c9;
      padding: 1px;
      background-color: #edeef8;
      border-radius: 10px;
      // border: 1px solid #d7d9e9;
    }
  }
}
