@import "src/index.scss";

.title-text-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
}

.forward-search-modal {
  .title-text1-container {
    padding: 20px 15px 0px 20px;
    display: flex;
    width: 100%;
    flex-direction: row;
    justify-content: flex-start;

    .titleText {
      color: #4a4a4a;
      margin: $default-margin;

      font-size: 24px;
      font-weight: bold;
      letter-spacing: 0;
      line-height: 38px;
    }

    .closeButton {
      justify-content: flex-end;
    }
  }

  .forward-search-content {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: fit-content;
    background-color: white;
    width: 40vw;
    max-width: 600px;

    .title-text-container {
      padding-top: 20px;
      padding-bottom: 20px;
      display: flex;
      flex-direction: row;
      align-items: center;
      flex-wrap: wrap;
      margin: $default-margin;

      .access-label {
        align-self: flex-start;
        font-size: 18px;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        margin: $default-margin;
      }

      .refresh-btn {
        background-color: #6E8CC8 !important;
        @include default-button(white, rgb(74, 74, 74));
        margin: $default-margin;
      }

      .refresh-btn:disabled {
        background-color: lightgray !important;
        @include default-button(white, rgb(74, 74, 74));
        margin: $default-margin;
      }
    }
  }

  .action-container {
    .action-btn {
      margin: $default-margin;
    }

    .save-btn {
      margin: $default-margin;
      background-color: #0014DC !important;
      @include default-button(white, rgb(74, 74, 74));
    }
  }
}

@media only screen and (max-width:$tablet-mini) {
  .forward-search-modal {
    .forward-search-content {
      width: unset;
    }
  }
}