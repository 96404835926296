@import "src/index.scss";

$systen-cell-width: calc(80px);
$modal-width: calc(#{$systen-cell-width} * 4 + 200px);
$modal-width-Tablet-Mx: 80vw;

.system-selection-modal {
  .MuiPaper-root {
    width: $modal-width;
    max-width: $modal-width;
  }
  width: 100%;
  padding: 0px;
  display: flex;
  flex-direction: row;
  margin-left: 5%;
  justify-content: flex-start;
  .system-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .system-title-heading {
      color: #4a4a4a;
    }
  }
  .MuiDialogContent-root {
    padding: 0px;
    min-height: 15rem;
    min-width: 20rem;
  }

  .MuiDialogTitle-root {
    padding: 0px 24px;
  }
  .MuiDialog-paper {
    margin-left: 0px;
    position: inherit;
  }
  .system-dialog {
    border-radius: 20px;
    max-width: 26rem;
  }
  .system-container {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    padding: 20px;
    .nosystem {
      padding: 20px;
      font-size: 16px;
      font-style: italic;
      color: #9b9b9b;
    }
    .system-row {
      width: 25%;
      .system-row-container {
        position: relative;
        height: calc(#{$systen-cell-width} + 20px);
        width: calc(#{$systen-cell-width} + 20px);

        .system-cell {
          position: absolute;
          padding: 15px 5px 5px 5px;
          display: flex;
          flex-direction: column;
          border-radius: 8px;
          height: $systen-cell-width;
          width: $systen-cell-width;
          align-items: center;
          background-color: #051464;

          .system-img {
            height: 25px;
            width: 25px;
          }
        }

        .disable-system-cell {
          @extend .system-cell;
          background-color: rgb(195, 195, 195);
        }

        .checkbox {
          position: absolute;
          top: 0px;
          left: 0px;
          svg {
            background: white;
            border-radius: 5px;
          }
        }

        .description {
          color: white;
          margin: 5px 0 0 0;
          text-align: center;
          font-size: 12px;
        }
      }
    }
  }
}

@media only screen and (max-width: $tablet-mini) {
  .system-selection-modal {
    .MuiDialogContent-root {
      max-height: 20rem;
    }
    .MuiDialog-container {
      margin-left: 3rem;
    }
    .system-container {
      .system-row {
        width: 28%;
      }
      padding: 6px;
    }
  }
}
