@import "src/index.scss";

$modal-width: 60vw;
$systen-cell-width: calc(130px);

$modal-width-Tablet-Mx: 80vw;

.user-guide-profile-modal {
  .label-3 {
    padding-left: 10px;
    font-size: 18px;
  }

  .MuiPaper-root {
    width: $modal-width;
    max-width: $modal-width;
  }

  .title-text1-container {
    padding: 20px 15px 0px 20px;
    display: flex;
    width: 100%;
    flex-direction: row;
    justify-content: flex-start;

    .titleText {
      color: #4a4a4a;
      margin: $default-margin;
      font-size: 24px;
      font-weight: bold;
    }

    .closeButton {
      justify-content: flex-end;
    }
  }

  .user-guide-content {
    .detail-row {
      .MuiButtonBase-root {
        background-color: #f0f1fa !important;
      }
      .MuiIconButton-label {
        padding: 1px;
        background-color: white;
        border-radius: 10px;
        border: solid 1px #d7d9e9;
        .MuiSvgIcon-root {
          color: #adb0c9;
        }
      }

      .header-content {
        height: 19px;
        color: #6d7296;
        font-size: 15px;
        font-weight: 600;
        letter-spacing: 0;
        line-height: 19px;

        .headerCount {
          float: right;
          border-radius: 13px;
        }
      }

      .accordian-container {
        border-bottom: 1px solid rgba(224, 224, 224, 1);
      }

      border: 1px solid rgba(224, 224, 224, 1);

      .accordian-header {
        height: 19px;
        color: #6d7296;
        font-size: 15px;
        font-weight: 600;
        letter-spacing: 0;
        line-height: 19px;
      }

      .MuiAccordionDetails-root {
        display: contents;
        padding: 8px 0px 16px;
      }

      .MuiTableCell-root {
        padding: 0px;
        border-bottom: none;
      }

      .MuiPaper-elevation1 {
        box-shadow: none;
        padding: 1%;
        padding-right: 6%;
      }

      .MuiCardMedia-media {
        max-width: 450px;
      }
    }

    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: white;
    width: 100%;

    .divider-1 {
      margin-top: 10px;
      margin-bottom: 20px;
      width: 100%;
    }

    .divider-2 {
      width: 100%;
    }

    .information {
      display: flex;
      width: 100%;
      text-align: start;
      margin: $default-margin;
    }

    .title-text-container {
      display: flex;
      flex-direction: row;
      align-items: center;
      margin: $default-margin;
      padding-left: 7px;

      .label {
        font-size: 18px;
        margin: $default-margin;
      }

      .label-2 {
        font-style: italic;
      }

      .refresh-btn {
        background-color: #0014dc !important;
        font-weight: 400;
        @include default-button(white, rgb(74, 74, 74));
        margin: $default-margin;
      }
    }

    .video-area {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;
      font-weight: 600;
      margin: $default-margin;
      flex-wrap: wrap;

      .video-container {
        margin: 10px;
        .sys-name {
          color: #9b9b9b !important;
          font-weight: 500 !important;
          font-style: italic !important;
          font-size: 16px !important;
          padding-bottom: 10px;
        }

        .video {
          max-width: 380px;
          width: 616px;
          height: 270px;
        }
      }
    }
  }

  .action-container {
    .action-btn {
      margin: $default-margin;
    }

    .save-btn {
      margin: $default-margin;
      background-color: #0014dc !important;
      @include default-button(white, rgb(74, 74, 74));
    }
  }
  .sys-name {
    color: #0014dc;
    font-weight: 600;
    font-size: 20px !important;
  }
  .video-section {
    margin: 8px;
  }
}

@media only screen and (max-width: $tablet-mini) {
  .user-guide-profile-modal {
    .MuiPaper-root {
      width: $modal-width-Tablet-Mx;
      max-width: $modal-width-Tablet-Mx;
    }
    .user-guide-content {
      .detail-row {
        .MuiPaper-elevation1 {
          box-shadow: none;
          padding: 1%;
          padding-right: 13%;
        }
      }
    }

    .user-guide-content .video-area .video-container .video {
      width: 70vw;
      height: auto;
    }
  }
}

@media only screen and (max-width: $mobile) {
  .user-guide-profile-modal {
    .MuiPaper-root {
      width: $modal-width-Tablet-Mx;
      max-width: $modal-width-Tablet-Mx;
    }
    .user-guide-content {
      .detail-row {
        .MuiPaper-elevation1 {
          box-shadow: none;
          padding: 1%;
          padding-right: 13%;
        }
      }
      .sys-name {
        color: #0014dc;
        font-weight: 400;
        font-size: 18px !important;
      }
    }

    .user-guide-content .video-area .video-container .video {
      width: 70vw;
      height: auto;
    }
  }
}
