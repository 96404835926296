.vehicle-details {
  border: 1px solid #e0e0e0;
  margin-bottom: 20px;

  .ejourney-sec-header {
    background-color: #e9ebf5 !important;
    padding: 12px !important;
    color: black;
    font-size: 16px;
    font-weight: 700;
  }

  .header-right {
    float: right !important;
  }

  .tableLayout {
    width: 100%;

    .traveller-header {
      color: #6d7296;
    }
  }

  .tableMainLayout{
    width: 100%;
  }

  .panelheader {
    background-color: #e9ebf5;
    padding: 16px;
    padding-bottom: 15px;
    color: black;

    .panelheadertext {
      font-size: 16px;
      margin-bottom: 10px;
    }
  }

  .td-color {
    color: #9b9b9b;
  }

  .td-color-black {
    color: black;
  }
  .border-bottom-container {
    border-bottom: 2px solid #e0e0e0;
  }
  .border-bottom-container:last-child {
    border-bottom: 1px solid #e0e0e0;
  }
  .rowContainer {
    border-bottom: 1px solid #e0e0e0;

    .loadType {
      font-size: 13px;
      color: rgb(109, 114, 150);
      padding: 13px 6px;
    }

    .loadTypeValue {
      font-size: 13px;
      font-weight: 600;
      padding: 13px 6px;
    }
  }
}
