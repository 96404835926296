@import "src/index.scss";

.quest-section {

  .link-container {
    border: solid;
    border-color: lightgrey;
    border-width: 1px;
    border-radius: 7px;
    padding: 15px;
    margin-top: 10px;
    min-width: 500px;

    .quest-link-container {
        display: flex;
      .attachment-icon {
          justify-content: center;
          align-items: center;
          display: flex;
          padding: 10px;
      }
      .link-text {
        flex-direction: column;
        .quest-link-title {
          display: flex;
          font-size: 14px;
          margin: 15px 0;
          padding-left: 10px;
          padding-right: 5px;
        }

        .quest-link-url {
          display: flex;
          font-size: 14px;
          margin: 15px 0;
          padding-left: 10px;
          color: #0014DC !important;
          padding-right: 5px;
        }
      }
    }
  }
  .no-link-container {
    color: #9b9b9b;
    display: flex;
    justify-content: center;
    margin: $default-margin;
    font-weight: bold;
    padding-top: 30px;
  }
}
