@import "src/index.scss";

.watershead-header {
    background-color: white;

    .row-1 {
        .dot {
            background-color: $task-sheet;
        }

        .label-1 {
            color: $task-sheet;
            font-size: 14px;
        }

        .label-2 {
            font-size: 14px;
            color: #83a7b2;
        }
    }

    .row-2 {
        .label-3 {
            font-size: 18px;
            color: #4a4a4a;
        }
    }

    .row-3 {
        .key-value-1 {
            .key {
                font-size: 30px;
                font-weight: 700;
                color: #4a4a4a;
            }

            .value {
                font-size: 16px;
                font-weight: normal;
                color: #9b9b9b;
            }
        }
    }
}