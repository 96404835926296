.pr-items-row-text-1 {
  font-size: 12px;
  font-weight: bold;
  margin: 0;
}

.pr-items-row-key-value-1 {
  .key {
    font-size: 12px;
    font-weight: bold;
    color: #4a4a4a;
  }

  .value {
    font-size: 12px;
    font-weight: bold;
    color: #9b9b9b;
  }
}

@mixin pr-items-row() {
  border: 1px solid rgba(224, 224, 224, 1);
}

.pr-items-row-1 {
  @include pr-items-row();
  background-color: #e8e8f7;
}

.pr-items-row-2 {
  @include pr-items-row();
  background-color: #f0f0fb;
}

.pr-items-row-3 {
  @include pr-items-row();
  background-color: #f0f0fb;
}

.pr-items-row-4 {
  @include pr-items-row();

  .key-value-list {
    margin: 0;
  }
}

.pr-items-row-5 {
  @include pr-items-row();

  .other-details {
    padding: 0px;
  }

  .key-value-list {
    margin: 12px 0 12px 0;
  }
}

.pr-items-row-6 {
  @include pr-items-row();

  .key-value-list {
    margin: 12px 0 12px 0;
  }
}

.pr-items-row-7 {
  @include pr-items-row();
  padding: 0px;

  .split-item-details {
    padding: 0px;
  }
}

.pr-items-spacing {
  height: 12px;
}

.pr-items-tab {
  .custom-table .table-row .table-cell .header-cell-content .header-label {
    font-size: 12px;
    color: #7c7c7c;
    min-width: 100px;
  }
}

.currency {
  font-size: 12px;
  font-weight: bold;
  color: #9b9b9b;
}