@import "src/index.scss";

.swps-comments-tab{
    .no-comments{
        color: #9b9b9b;
        display: flex;
        justify-content: center;
        margin : $default-margin;
        font-weight: bold;
        padding-top: 30px;
    }
}