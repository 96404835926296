.pr-details-key-value {
    overflow: visible;

    .key-value-list .key-value .key {
        min-width: 103px;
        text-align: start;
        display: flex;
        flex-wrap: wrap;
        font-size: 14px;
    }
}