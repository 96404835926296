.detailDriving {

    border: 1px solid #e0e0e0;
    margin-bottom: 20px;

    .col-1 {
        width: 30%
    }

    .col-2 {
        width: 80%
    }

    .col-3 {
        width: 10%;
    }

    .risk-header {
        float: right
    }

    .risk-header-Text {
        font-size: 1rem;
        font-weight: 100 !important;
        line-height: 1.5;
        letter-spacing: 0.00938em;
        color: #6d7296;


    }

    .riskCount {
        float: right;
    }

    .tableLayout {
        table-layout: fixed;
        width: 100%;
        color: black;
    }

    .score {
        float: right;
        margin-right: 20%;
        font-size: 13px;
    }

    .riskScore {
        float: right;
    }

    .panelheader {
        background-Color: #e9ebf5;
        padding: 12px 10px 10px 10px;
        color: black;

        .panelheadertext {
            font-size: 16px;
            margin-bottom: 12px;
        }

        .row-detail {
            margin: 8px;
            color: #6d7296;
            font-size: 14px;
            letter-spacing: 0;
            line-height: 19px;
        }
    }

    .rowvalue {
        color: #9b9b9b;
        font-size: 13px;
    }

    .rowrowanswer {
        font-size: 13px;
    }

    .Low {
        color: #3CBE46;
    }

    .Medium {
        color: #EA910D;
    }

    .High {
        color: #EB5E5E;
    }

    .Extreme {
        color: black;
    }

    .border-bottom-container {
        border-bottom: 1px solid #e0e0e0;

    }
}