.driver-fatigue {
  border: 1px solid #e0e0e0;
  margin-bottom: 20px;

  .border-bottom-container {
    border-bottom: 2px solid #e0e0e0;
  }
  .border-bottom-container:last-child {
    border-bottom: 1px solid #e0e0e0;
  }

  .panelheader {
    background-color: #e9ebf5;
    padding: 16px;
    padding-bottom: 15px;
    color: black;

    .panelheadertext {
      font-size: 16px;
    }

    .score {
      float: right;
    }
  }

  .rest-red-Status {
    color: #eb5e5e;
    font-size: 14px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 19px;
  }

  .rest-green-Status {
    color: green;
    font-size: 14px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 19px;
  }

  .padding-row {
    padding: 8px;
  }

  .grid-container {
    display: flex;
    flex-direction: row;
    height: 15px;
    width: 100%;
    padding-top: 6px;
    padding-bottom: 12px;
  }

  .grid-container1 {
    color: #a2a2a2;
    padding-top: 10px;
    font-size: 14px;
    justify-content: space-between;
  }

  .grid-container2 {
    padding-left: 3px;
  }

  .grid-Container3 {
    font-weight: 600;
    font-size: 14px;
  }

  .tableRow {
    color: #6d7296;

    .tableHeader {
      color: inherit;
      border: none;
      padding: 4px;
    }
  }

  .tableCell:nth-child(odd) {
    border: none;
    padding: 4px;
    font-weight: 600;
    color: #3cbe46;
  }
  .tableCell:nth-child(even) {
    border: none;
    padding: 4px;
    font-weight: 600;
    color: #ea910d;
  }

  .rowContainer {
    border-bottom: 1px solid #e0e0e0;

    .restedHours {
      font-size: 13px;
      color: rgb(109, 114, 150);
      padding: 13px 6px;
    }

    .restedValue {
      font-size: 13px;
      font-weight: 600;
      padding: 13px 6px;
    }
  }

  .driver-name {
    color: #000;
    font-size: 14px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 19px;
  }
}
