@import "src/index.scss";

.custom-table{
  box-shadow: none;

  .table-row{
    background-color : rgba(202,203,239,0.33);

    .table-cell{
      border: none;
      padding: 7px 16px;

      .header-cell-content{
        display: flex !important;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        width: auto;

        .header-label{
          font-size: 12px;
          color: #7c7c7c;
          min-width: 50px;
        }
        
        .sorting-icon{
          height: 12px;
          width: auto;
          transform: rotate(90deg);
          margin : $default-margin;
          color: #7c7c7c;
        }
      }

      .has-sorting{
        cursor : pointer;
      }
      
      .no-sorting{
        cursor : normal;
      }
    }

    .table-body{
      border : none;
    }
  }
}