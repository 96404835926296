@import "src/index.scss";

.ejourney-card {
  .row-1 {
    .dot {
      background-color: $ejourney;
    }

    .label-1 {
      font-size: 14px;
      color: $ejourney;
    }

    .chip-1 {
      .label {
        color: white;
      }
    }
  }

  .row-2 {
    .label-2 {
      font-size: 18px;
      color: #4a4a4a;
    }
  }

  .row-4 {
    .col-1 {
      .key-value-2 {
        .key {
          font-size: 14px;
          font-weight: 700;
          color: #4a4a4a;
        }

        .value {
          font-size: 14px;
          font-weight: normal;
          color: #4a4a4a;
        }
      }
    }
  }

  .key-value {
    margin: 1px;
  }

  @media (min-width: 375px) and (max-width: 400px) {
    .user-details {
      max-width: 62%;
    }
  }

  @media (min-width: 1025px) {
    .user-details {
      max-width: 55%;
    }
  }

  @media (min-width: 540px) and (max-width: 720px) {
    .user-details {
      max-width: 58%;
    }
  }

  @media (min-width: 412px) and (max-width: 539px) {
    .user-details {
      max-width: 65%;
    }
  }

}

.chip-1-high {
  .row-1 .chip-1 {
    background-color: #EB5E5E;
  }
}

.chip-1-medium {
  .row-1 .chip-1 {
    background-color: #EA910D;
  }
}

.chip-1-low {
  .row-1 .chip-1 {
    background-color: #3CBE46;
  }
}

.chip-1-extreme {
  .row-1 .chip-1 {
    background-color: black;
  }
}

.label-3-compliant {
  .row-4 .col-1 .label-3 {
    color: #3CBE46;
    font-weight: 600;
    text-align: end;
  }
}

.driverCount{
  margin: 5px;
}

.label-3-non-compliant {
  .row-4 .col-1 .label-3 {
    color: #EB5E5E;
    font-weight: 600;
    text-align: end;
  }
}