@import "src/index.scss";

.po-header {
  background-color: white;
  
  .row-1 {
    .dot {
      background-color: $po;
    }

    .label-1 {
      color: $po;
      font-size: 14px;
    }

    .label-2 {
      font-size: 14px;
      color: #83a7b2;
    }
  }

  .row-2 {
    .label-3 {
      font-size: 18px;
      color: #4a4a4a;
    }
  }

  .row-3 {
    .key-value-1 {
      .key {
        font-size: 30px;
        font-weight: 700;
        color: #4a4a4a;
      }

      .value {
        font-size: 16px;
        font-weight: normal;
        color: #9b9b9b;
      }
    }
  }
}

.po-optimus{
  .row-1{
    .dot{
      background-color: #136FD5;
    }
    .label-1{
      color: #136FD5;
    }
  }
}


