.dialog-pwa {
  .title {
    padding: 0px;
    .title-header {
      margin: 0px 10px;
      .header {
        .button-close-container {
          display: flex;
          flex-direction: row;
          justify-content: flex-end;
          align-items: center;
          padding-top: 10px;

          
        }
      }
    }
  }

  .content {
    background-color: #ffffff;
    padding: 8px 10px;
    .app-icon {
      width: 36px;
      height: 36px;
      border-radius: 10px;
    }

    .title {
      padding-left: 8px;
      // font-family: inherit;
      display: flex;
      align-items: center;
      color: #0414d3;
      font-size: 20px;
      font-weight: 700;
      word-wrap: break-word;
    }

    .button-container {
      padding-top: 10px;
      .dialog-button {
        color: white !important;
        width: 100%;
        cursor: pointer;
        font-weight: 600;
        background-color: #0014dc;
        text-transform: none;
        height: 37px;
      }
    }
  }
  .MuiDialog-scrollPaper {
    justify-content: flex-end;
    align-items: flex-start;
  }
  .MuiButtonBase-root {
    padding: 0px;
  }
}
