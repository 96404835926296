@import "src/index.scss";

.message-1 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: $default-margin;

    .title {
        font-size: 16px;
        margin: $default-margin;
        color: #9b9b9b;
        font-weight: normal;
        text-align: center;
    }

    .sub-title {
        font-size: 14px;
        margin: $default-margin;
        color: whitesmoke;
        text-align: center;
    }

    .message-img {
        width: 65px;
        height: auto;
        margin: $default-margin;
    }
}