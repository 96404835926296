@import "src/index.scss";

.po-items-tab {
  .key {
    font-size: 12px;
    font-weight: bold;
    color: #4a4a4a;
    margin: 0 5px 0 0;
  }

  .value {
    font-size: 11px;
    font-weight: bold;
    color: #9b9b9b;
    margin: 0;
  }

  margin-top: 20px;

  .no-po-items {
    padding: 8px;
    text-align: center;
    font-weight: 600;
  }

  .MuiAccordion-rounded:last-child {
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
  }

  .MuiAccordion-rounded:first-child {
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
  }

  .main-row {
    border: 1px solid rgba(224, 224, 224, 1);

    .table-cell {
      font-weight: 600;
    }
  }

  .sub-row {
    border-left: 1px solid rgba(224, 224, 224, 1);
    border-right: 1px solid rgba(224, 224, 224, 1);
    border-bottom: 1px solid rgba(224, 224, 224, 1);
  }

  .spacing {
    height: 12px;
  }

  .sub-row-content {
    .key-value-list {
      margin: 0;
    }
  }

  .border-div {
    .key-value-list {
      margin: 10px;

      .key {
        width: 180px;
        min-width: 180px;
        font-weight: 500;
        color: #979797;
      }

      .value {
        color: black;
      }
    }
  }

  .po-items-row-text-1 {
    font-size: 12px;
    font-weight: bold;
    margin: 0;
  }

  .po-items-row-key-value-1 {
    .key {
      font-size: 12px;
      font-weight: bold;
      color: #4a4a4a;
    }

    .value {
      font-size: 12px;
      font-weight: bold;
      color: #9b9b9b;
    }
  }

  .po-optimus-item-rows {
    .po-items-row-text-1 {
      font-size: 12px;
      font-weight: bold;
      margin: 0;
    }

    .po-items-row-key-value-1 {
      .key {
        font-size: 12px;
        font-weight: bold;
        color: #4a4a4a;
      }

      .value {
        font-size: 12px;
        font-weight: bold;
        color: #9b9b9b;
      }
    }

    @mixin po-items-row() {
      border: 1px solid rgb(224, 224, 224);
    }

    .po-items-row-1 {
      @include po-items-row();
    }

    .po-items-row-2 {
      @include po-items-row();
      background-color: #f0f0fb;
    }

    .po-items-row-3 {
      @include po-items-row();
      background-color: #f0f0fb;
    }

    .po-items-row-4 {
      @include po-items-row();

      .key-value-list {
        margin: 0;
      }
    }

    .po-items-row-5 {
      @include po-items-row();

      .other-details {
        padding: 0px;
      }

      .key-value-list {
        margin: 12px 0 12px 0;
      }
    }

    .po-items-row-6 {
      @include po-items-row();

      .key-value-list {
        margin: 12px 0 12px 0;
      }
    }

    .po-items-row-7 {
      @include po-items-row();
      padding: 0px;

      .split-item-details {
        padding: 0px;
      }
    }

    .po-items-spacing {
      height: 12px;
    }

    .change-log-row {
      border: 1px solid rgb(224, 224, 224);

      .MuiTableCell-root {
        padding: 3px 0px 0px 16px;
      }

      .change-log-text {
        font-weight: bold;
        color: black;
        font-size: 12px;
      }

      @mixin po-items-row() {
        border: 1px solid rgb(224, 224, 224);
        background: #e8e8f8;
      }

      .po-items-row-1 {
        @include po-items-row();
      }

      .reason-text {
        color: blue;
        font-size: 14px;
        font-weight: 600;
        letter-spacing: 0;
        line-height: 16px;
        display: block;
      }

      .po-items-row-text {
        font-weight: bold;
        color: black;
        font-size: 12px;
        margin: 0;
      }

      .red-text {
        color: red;
        margin-left: -4px;
      }

      .green-text {
        color: green;
      }
    }
  }

  .dividerDiv {
    box-sizing: border-box;
    height: 1px;
    border: 0.5px solid #979797;
    opacity: 0.23;
    margin: 5px -10px;
  }
}