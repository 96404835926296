@import "src/index.scss";

.key-value-list {
  display: flex;
  flex-direction: column;
  margin: 20px 0 20px 0;

  .key-value {
    align-items: flex-start;
    margin : 5px 10px;

    .key {
      width: 150px;
      min-width: 150px;
      text-align: start;
      display: flex;
      flex-wrap: wrap;
      font-size: 14px;
    }

    .value{
      font-weight: normal;
      font-size: 14px;
    }
  }
}
