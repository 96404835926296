@import "src/index.scss";

.watershed-card {
  .row-1 {
    .dot {
      background-color: $task-sheet;
    }

    .label-1 {
      color: $task-sheet;
    }
  }
}
