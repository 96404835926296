@import "src/index.scss";

.dlpdetail-container {
  min-width: 600px;
  .MuiTableCell-root {
    padding: 0px 0px 0px 16px;
  }

  .title-container {
    margin-top: 0px;
    color: #4a4a4a;
    border: 1px solid #e0e0e0;
    background-color: rgba(202, 203, 239, 0.33);

    .dlp-section-title {
      align-items: center;
      display: flex;
      font-size: 14px;
      margin: 15px 0;
      padding-left: 10px;
      color: #7c7c7c;
      padding-right: 5px;

      .MuiChip-root {
        height: 27px;
      }
    }

    .chip-1-high {
      color: white;
      background-color: #EB5E5E;
    }

    .chip-1-medium {
      color: white;
      background-color: #EA910D;
    }

    .chip-1-low {
      color: white;
      background-color: #3CBE46;
    }
  }

  .subtitle-container {
    border: 1px solid #e0e0e0;
    margin-bottom: 20px;

    .dlp-details-row-title {
      padding-top: 5px;
      font-size: 14px;
      margin: 15px 0;
      padding-left: 10px;
      color: #9b9b9b;
      background-color: white;
      padding-right: 5px;
    }

    .dlp-details-row-answer {
      font-size: 14px;
      margin: 15px 0;
      padding-left: 10px;
      font-weight: 600;
      background-color: white;
      padding-right: 5px;
    }
  }

  .team-details-table {
    border: 1px solid #e0e0e0;
    margin-bottom: 20px;
  }
}