@import "src/index.scss";

.pretrip-trip-container {
  min-width: 600px;
  .main-container {
    .title-container {
      margin-top: 20px;
      color: #4a4a4a;
      border: 1px solid #e0e0e0;
      background-color: rgba(202, 203, 239, 0.33);

      .pretrip-section-title {
        align-items: center;
        display: flex;
        font-size: 14px;
        margin: 15px 0;
        padding-left: 10px;
        color: #7c7c7c;
        padding-right: 5px;
      }
    }
  }

  .subtitle-container {
    border: 1px solid #e0e0e0;

    .key-value-list .key-value .value {
      margin-left: 50px;

      .load-Value2 {
        color: yellow;
      }
    }
  }

  .key-value-list {
    display: flex;
    flex-direction: column;
    margin: 5px 0 5px 0;
  }
}