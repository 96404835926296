@import "src/index.scss";

.pretrip-trip-container {
  .main-container {
    .title-container {
      margin-top: 20px;
      color: #4a4a4a;
      border: 1px solid #e0e0e0;
      background-color: rgba(202, 203, 239, 0.33);

      .pretrip-section-title {
        align-items: center;
        display: flex;
        font-size: 14px;
        margin: 15px 0;
        padding-left: 10px;
        color: #7c7c7c;
        padding-right: 5px;
      }
    }
  }

  .subtitle-container {
    border: 1px solid #e0e0e0;

    .key-value-list .key-value {
      .traveller-name {
        .name {
          font-weight: 600;
          margin-bottom: -5px;
          margin-top: 0px;
        }

        .main {
          display: flex;
          justify-content: flex-start;
          align-items: center;
          margin-bottom: 5px;
          height: 25px;

          // .details-popup {
          //   margin-left: 70px;
          //   color: #136fd5;
          //   text-decoration: underline;
          //   cursor: pointer;
          //   font-weight: 600;
          // }

          .email {
            color: #9b9b9b;
            display: flex;
            flex-direction: row;
            min-width: 151px;
          }
        }
      }

      .key {
        display: inline;

        .count {
          display: inline;
          font-weight: 300;
          color: black;
        }

        min-width: 220px;
      }

      .job-title {
        color: #9b9b9b;
      }
    }
  }

  .traveller-trip {
    width: 50%;
    display: inline-block;
    vertical-align: top;
    flex-direction: column;
    padding-left: 10px;
    padding-bottom: 10px;
  }

  .origin-key {
    color: #9b9b9b;
    line-height: 16px;
    margin-bottom: 1px;
  }

  .origin-value {
    color: #000000;
    font-weight: bold;
    margin-bottom: 1px;
    margin-top: 1px;
  }

  .traveller-destination-border {
    border-left: 0.5px solid lightgrey;
  }

  .riskLabel {
    color: #9b9b9b;
    font-size: 12px;
    letter-spacing: 0;
    line-height: 16px;
    margin-bottom: 1px;
    margin-top: 0.4rem;

    .risk {
      margin-left: 8px;
      font-weight: 700;
    }

    .risk-low {
      color: #3CBE46;
    }

    .risk-high {
      color: #EB5E5E;
    }

    .risk-medium {
      color: #EA910D;
    }

    .risk-extreme {
      color: black;
    }

    .risk-insignificant {
      color: #1946A2;
    }
  }

  .country-covid-riskLink {
    color: #0014DC;
    font-size: 12px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 16px;
    cursor: pointer;
    margin-left: 70px;
  }
}

@media only screen and (max-width: 375px) {
  .pretrip-trip-container {
    .subtitle-container {
      border: 1px solid #e0e0e0;
  
      .key-value-list .key-value {
        .load-Value{
          margin-left:30px
        }
        .traveller-name {
          .name {
            font-weight: 600;
            margin-bottom: -5px;
            margin-top: 0px;
          }
  
          .main {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            margin-bottom: 5px;
            height: 25px;
  
  
            .email {
              color: #9b9b9b;
              display: flex;
              flex-direction: row;
              min-width: 151px;
            }
          }
        }
  
        .key {
          display: inline;
  
          .count {
            display: inline;
            font-weight: 300;
            color: black;
          }
  
          min-width: 220px;
        }
        .col-1{
          margin-left:-30px;
        }
  
        .job-title {
          color: #9b9b9b;
        }
      }
    }
  }
}