@import "src/index.scss";

.sidebar-app-content {
  display: flex;
  flex-direction: row;

  .sidebar-placeholder {
    display: flex;
    flex-direction: column;
  }

  .sidebar-close{
    display: none;
  }

  .app-content-placeholder {
    height: calc(100vh - #{$app-header-height});
    width: calc(100vw - #{$app-sidebar-width});
    background: #0014DC;
  }

  .app-content-placeholder-expanded{
    width: 100vw;
  }
}
