@import "src/index.scss";

.po-items-tab {
  margin-top: 20px;

  .no-po-items {
    color: #9b9b9b;
    display: flex;
    justify-content: center;
    margin: 5px;
    font-weight: 600;
    padding-top: 30px;
  }

  .MuiAccordion-rounded:last-child {
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
  }

  .MuiAccordion-rounded:first-child {
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
  }

  .no-items {
    color: #9b9b9b;
    display: flex;
    justify-content: center;
    margin: $default-margin();
    font-weight: bold;
  }

  .main-row {
    border: 1px solid rgba(224, 224, 224, 1);

    .table-cell {
      font-weight: 600;
    }
  }

  .sub-row {
    border-left: 1px solid rgba(224, 224, 224, 1);
    border-right: 1px solid rgba(224, 224, 224, 1);
    border-bottom: 1px solid rgba(224, 224, 224, 1);
  }

  .spacing {
    height: 12px;
  }

  .sub-row-content {
    .key-value-list {
      margin: 0;
    }
  }

  .po-items-details-sec {
    .MuiAccordionDetails-root {
      display: unset;
      padding: 0px;
    }

    .border-div {
      border: 1px solid rgba(224, 224, 224, 1);
    }
  }

  .po-items-row-text-1 {
    font-size: 12px;
    font-weight: bold;
    margin: 0;
  }

  .po-items-row-key-value-1 {
    .key {
      font-size: 12px;
      font-weight: bold;
      color: #4a4a4a;
    }

    .value {
      font-size: 12px;
      font-weight: bold;
      color: #9b9b9b;
    }
  }

  .detail-row {
    .quantitySection{
      align-items: flex-start;
      margin: 5px 10px;
      .text{
        font-weight: 400;
        font-size: 12px;
        line-height: 17px;
        color: #000000;
      }
    }
    .MuiIconButton-label {
      color: #adb0c9;
      padding: 1px;
      background-color: #edeef8;
      border-radius: 10px;
    }

    .pricing-currency {
      color: #9B9B9B;
      font-size: 12px;
      letter-spacing: 0;
      line-height: 14px;
      text-align: right;
    }

    .header-content {
      height: 19px;
      color: #6D7296;
      font-size: 15px;
      font-weight: 600;
      letter-spacing: 0;
      line-height: 19px;

      .headerCount {
        float: right;
        border-radius: 13px;
      }
    }

    .accordian-container {
      border-bottom: 1px solid rgba(224, 224, 224, 1);
    }

    border: 1px solid rgba(224, 224, 224, 1);

    .accordian-header {
      height: 19px;
      color: #6D7296;
      font-size: 15px;
      font-weight: 600;
      letter-spacing: 0;
      line-height: 19px;
    }
    

    .detail-value {

      .value {
        color: #000000;
        font-size: 12px;
        font-weight: 400;
      }
    }

    .shipping-detail-title {
      height: 19px;
      margin-left: 1%;
      color: #6B6E93;
      font-size: 16px;
      font-weight: 600;
      letter-spacing: 0;
      line-height: 19px;
    }

    .line {
      border-bottom: 1px solid #DFE1F2;
      margin-top: 3px;
    }

    .MuiAccordionDetails-root {
      display: contents;
      padding: 8px 0px 16px;
    }

    .MuiTableCell-root {
      padding:0px;
      border-bottom: none;
    }


    .MuiPaper-elevation1 {
      box-shadow: none;

    }

    .MuiButtonBase-root {
      background-color: #FAFAFD;
    }

    .account-assignment-table {
      td {
        padding: 16px 6px 2px 16px;
        color: #000000;
        font-size: 12px;
        font-weight: 400;
        letter-spacing: 0;
        line-height: 16px;
      }

      .custom-table .table-row .table-cell .header-cell-content .header-label {
           font-size: 12px;
           color: #7c7c7c;
           min-width: 50px;
      }

      th {
        padding: 16px 2px 2px 16px;
      }
    }
  }

  .po-item-rows {
    .unit{
      font-weight: 400;
      font-size: 12px;
      line-height: 14px;
      text-align: right;
      color: #9B9B9B;
    }
    .po-items-row-text-1 {
      font-size: 12px;
      font-weight: bold;
      margin: 0;
    }

    .po-items-row-key-value-1 {
      .key {
        font-size: 12px;
        font-weight: bold;
        color: #4a4a4a;
      }

      .value {
        font-size: 12px;
        font-weight: bold;
        color: #9b9b9b;
      }
    }

    @mixin po-items-row() {
      border: 0.5px solid #979797;
      background: #E8E8F8;
    }

    .po-items-row-1 {
      @include po-items-row();
    }

    .po-items-row-2 {
      @include po-items-row();
      background-color: #f0f0fb;
    }

    .po-items-row-3 {
      @include po-items-row();
      background-color: #f0f0fb;
    }

    .po-items-row-4 {
      @include po-items-row();

      .key-value-list {
        margin: 0;
      }
    }

    .po-items-row-5 {
      @include po-items-row();

      .other-details {
        padding: 0px;
      }

      .key-value-list {
        margin: 12px 0 12px 0;
      }
    }

    .po-items-row-6 {
      @include po-items-row();

      .key-value-list {
        margin: 12px 0 12px 0;
      }
    }

    .po-items-row-7 {
      @include po-items-row();
      padding: 0px;

      .split-item-details {
        padding: 0px;
      }
    }

    .po-items-spacing {
      height: 12px;
    }

    .change-log-row {

      border: 0.5px solid #979797;
      opacity: 0.66;
      background-color: #E8E8F8;

      .MuiTableCell-root {
        padding: 0px 0px 0px 16px;
      }

      .change-log-text {
        font-weight: bold;
        color: black;
        font-size: 12px;
      }

      @mixin po-items-row() {
        border: 0.5px solid #979797;
        background: #E8E8F8;
      }

      .po-items-row-1 {
        @include po-items-row();
      }

      .reason-text {
        color: blue;
        display: block;
      }

      .po-items-row-text {
        font-weight: bold;
        color: black;
        font-size: 12px;
        margin: 0;
      }

      .red-text {
        color: red;
        margin-left: -4px;
      }

      .green-text {
        color: green;
      }
    }
  }
}