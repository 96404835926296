@import "src/index.scss";

.search-filter-toolbar {
  margin: $default-margin;
  width: calc(100% - #{$default-margin}* 2) !important;

  .filter-icon {
    margin: $default-margin;
    width: 20px;
    height: 20px;
    cursor: pointer;
  }

  .disabled-icon {
    opacity: 0.5;
    pointer-events: none;
  }

  .row-1 {
    margin: $default-margin;
    height: 40px;

    .title {
      margin: $default-margin;
      width: auto;
      color: white;
      font-size: 20px;
      font-weight: 500;
    }

    .count {
      margin: $default-margin;
      padding: 5px 5px;
      width: auto;
      min-width: 30px;
      min-height: 30px;
      border-radius: 50px;
      background-color: rgb(255 255 255);
      text-align: center;
      font-size: 14px;
      font-weight: 700;
    }
  }

  .row-2 {
    margin: $default-margin;
    height: 40px;

    .search-bar-container {
      width: 75%;

      .search-bar {
        background-color: white;
        border-radius: 20px;
        width: 100%;
        max-width: 800px;

        .MuiInputBase-root {
          display: flex;
          height: 40px;
          border: 1px solid transparent !important;
          border-radius: 20px;
          background-color: #ffffff !important;
        }

        .MuiInputBase-input {
          width: 100%;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }

      .search-icon {
        margin: $default-margin;
        height: 20px;
        width: 20px;
        color: #9b9b9b;
      }

      .close-icon {
        margin: $default-margin;
        height: 20px;
        width: 20px;
        color: #9b9b9b;

        &:hover {
          cursor: pointer;
        }
      }
    }
  }

  .power-bi-icon-class {
    flex-direction: row-reverse;
  }

  .power-bi-link {
    text-decoration: none;
  }

  .icon-container {
    display: flex;
    align-items: center;
    border: 1px solid white !important;
    border-radius: 5px;
    padding: 6px;
    cursor: pointer;
    color: white;
    transition: opacity 0.2s ease-in-out;
  }

  .icon-container:hover {
    opacity: 0.8;
  }

  .power-bi-icon {
    width: 24px;
    height: 24px;
  }

  .icon-text {
    margin-left: 8px;
    font-size: 12px;
    text-decoration: none;
  }
}
