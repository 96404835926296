@import "src/index.scss";

.card-1 {
  padding: 10px;
  cursor: pointer;

  .row-1 {
    // flex-wrap: nowrap;
    word-break: break-all;
    .dot {
      margin: $default-margin;
      height: 10px;
      width: 10px;
      border-radius: 10px;
      background-color: gray;
    }

    .label-1 {
      margin: $default-margin;
    }

    .key-value-1 {
      flex-grow: 1;
      justify-content: flex-end;
      align-items: baseline;
    }
  }

  .row-2 {
    .label-2 {
      margin: $default-margin;
    }
  }

  

  .row-4 {
    // flex-wrap: nowrap;
    .col-1 {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      justify-content: center;

      .label-3 {
        margin: $default-margin;
        flex-grow: 2;
        justify-content: flex-start;
      }
    }
  }
}

@media only screen and (max-width:$mobile-mini) {
  .card-1 {
    font-size: 3px;
    padding: 10px;
    cursor: pointer;

    .row-1 {
      flex-wrap: nowrap;
      word-break: break-all;
      .dot {
        margin: $default-margin;
        height: 10px;
        width: 10px;
        border-radius: 10px;
        background-color: gray;
      }

      .label-1 {
        margin: $default-margin;
        font-size: 10px !important;
      }

      .key-value-1 {
        flex-wrap: wrap;
        .key {
          font-size: 10px !important;
        }
        .value {
          font-size: 10px !important;
        }
      }
    }

    .row-2 {
      .label-2 {
        margin: $default-margin;
        font-size: 10px !important;
      }
    }

    .row-3 {
      .key-value-2 {
        .key {
          font-size: 10px !important;
        }
        .value {
          font-size: 10px !important;
        }
      }
    }

    .row-4 {
      .UserDetails_userInitials__1PMST {
        font-size: 8px !important;
        width: 24px;
        height: 24px;
      }
      .UserDetails_name__2JQqM {
        font-size: 8px !important;
      }
      .UserDetails_organization__2pwxh {
        font-size: 6px !important;
      }

      #row-4 {
        font-size: 10px !important;
      }
      font-size: 10px !important;
      flex-wrap: nowrap;
      .col-1 {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        justify-content: center;
        
        .label-3 {
          margin: $default-margin;
          font-size: 10px !important;
          padding-top: 22px;
        }
      }
    }
  }
}
