@import "src/index.scss";

.empty-indicator-container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  justify-content: center;
  align-items: center;
}

.list-view-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  overflow: auto;

  .row-align-multiselect-filter {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }
  .empty-filter-container {
    display: flex;
    justify-content: flex-end;
    width: 100%;
  }

  .MuiSelect-select.MuiSelect-select {
    padding-left: 15px;
  }

  .multi-select-root-container {
    margin: $default-margin;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;

    .multi-select-label {
      margin: $default-margin;
      font-size: 18px;
      color: rgb(151, 227, 244);
    }

    .labelDisplay {
      margin-right: 1rem;
      display: block;
    }
    /* Media query to hide the element on mobile devices */
    @media (max-width: 600px) {
      .multi-select-label.labelDisplay {
        display: none; /* Hide the element on screens with a width of 600px or less (mobile) */
      }
    }
  }

  .multi-select-root-checkbox {
    padding: 5px;
    margin-left: 14px;
    color: white;

    svg {
      color: white;
    }

    &:hover {
      background-color: rgba(255, 255, 255, 0.2) !important;
    }
  }

  .multi-select-root-checkbox-disabled {
    padding: 5px;
    margin-left: 14px;
    color: white;

    svg {
      color: #979797;
    }
  }

  .list {
    width: 100%;
    overflow: auto;

    .list-item {
      margin: 15px 10px;
      width: calc(100% - 20px);

      .multi-select-checkbox {
        padding: 5px;

        &:hover {
          background-color: lighten($primary, 40);
        }
      }
    }
  }
}
