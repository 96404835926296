@import "src/index.scss";

.equote-card {
  .card-8 .row-1 .label-1 {
    font-size: 14px;
    color: $equote;
  }

  .row-1 {
    .dot {
      background-color: $equote;
    }

    .label-1 {
      font-size: 14px;
      color: $equote;
    }
  }

  .row-2 {
    .label-2 {
      font-size: 18px;
      color: #4a4a4a;
    }
  }
}
