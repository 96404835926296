@import "src/index.scss";

.title-text-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
}

.switch-profile-modal {
  .title-text1-container {
    padding: 20px 15px 0px 20px;
    display: flex;
    width: 100%;
    flex-direction: row;
    justify-content: flex-start;

    .titleText {
      color: #4a4a4a;
      margin: $default-margin;

      font-size: 24px;
      font-weight: bold;
      letter-spacing: 0;
      line-height: 38px;
    }

    .closeButton {
      justify-content: flex-end;
    }
  }

  .switch-profile-content {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: fit-content;
    background-color: white;
    width: 40vw;
    max-width: 600px;

    .title-text-container {
      display: flex;
      flex-direction: row;
      align-items: center;
      flex-wrap: wrap;
      margin: $default-margin;

      .access-label {
        align-self: flex-start;
        font-size: 18px;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        margin: $default-margin;

      }

      .refresh-btn {
        background-color: #0014DC !important;
        @include default-button(white, rgb(74, 74, 74));
        margin: $default-margin;
      }
    }

    .system-container {
      justify-content: center;
      display: flex;
      flex-wrap: wrap;
      width: 100%;
      padding-bottom: 18px;
      padding-right: 20px;

      .system-row {
        display: flex;
        flex-direction: row;

        .system-row-container {
          position: relative;
          height: 130px;
          width: 130px;

          .system-cell {
            position: absolute;
            top: 20px;
            left: 20px;
            justify-content: center;
            padding: 20px;
            display: flex;
            flex-direction: column;
            border-radius: 8px;
            height: 6.875rem;
            width: 6.875rem;
            align-items: center;
            background-color: #136fd5;

            .image {
              height: 30px;
              width: 30px;
            }
          }

          .disable-system-cell {
            @extend .system-cell;
            background-color: rgb(195, 195, 195);
          }

          .checkbox {
            position: absolute;
            top: 0px;
            left: 0px;
          }

          .description {
            color: white;
            margin: 10px 0 0 0;
            text-align: center;
            font-size: 12px;
          }
        }
      }
    }

    .buttonContainer {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
    }

    .question {
      margin: $default-margin;
      align-self: flex-start;
      color: yellow;
      font-weight: 500;
      font-size: 22px;
    }

    .requests {
      margin: $default-margin;
      align-self: flex-start;
      color: #4a4a4a;
      font-weight: bold;
    }

    .comment-ip {
      margin: $default-margin;
      width: calc(100% - 10px);
    }
  }

  .action-container {
    .action-btn {
      margin: $default-margin;
    }

    .save-btn {
      margin: $default-margin;
      background-color: #0014DC !important;
      @include default-button(white, rgb(74, 74, 74));
    }
  }
}

@media only screen and (max-width:$tablet-mini) {
  .switch-profile-modal {
    .switch-profile-content {
      width: unset;
    }
  }
}