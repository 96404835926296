@import "src/index.scss";

.pretrip-card {
  .row-1 {
    .dot {
      background-color: $pretrip;
    }

    .label-1 {
      font-size: 14px;
      color: $pretrip;
    }

    .label-2 {
      font-size: 18px;
      font-weight: bold;
      color: #4a4a4a;
    }
  }

  .row-2 {
    .label-3 {
      font-size: 18px;
      color: #4a4a4a;
    }
  }
  
  .row-4 {
    .label-4 {
      font-size: 14px;
      color: #83a7b2;
    }
  }
}

.is-compliant-green {
  .row-1 .label-2 {
    color: #3CBE46;
  }
}

.is-compliant-red {
  .row-1 .label-2 {
    color: #EB5E5E;
  }
}