@import "src/index.scss";

.dlp-header {
  background-color: white;

  .row-1 {
    .dot {
      background-color: $dlp;
    }

    .label-1 {
      font-size: 14px;
      color: $dlp;
    }
  }

  .row-2 {
    .label-2 {
      font-size: 18px;
      color: #4a4a4a;
    }
  }

  .row-3 {
    .col-1 {
      .chip-1 {
        .label {
          color: white;
          text-align: center;
        }
      }
    }
  }
}

.chip-1-high {
  .row-3 .col-1 > .chip-1 {
    background-color: #eb5e5e;
  }
}

.chip-1-medium {
  .row-3 .col-1 > .chip-1 {
    background-color: #ea910d;
  }
}

.chip-1-low {
  .row-3 .col-1 > .chip-1 {
    background-color: #3cbe46;
  }
}
